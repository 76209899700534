import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import AuthLayout from '../AuthLayout';
import { useMutation, useQuery } from 'react-query';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import HistoryApiHook from '../../../hooks/HistoryApiHook';
import moment from 'moment';
import useThemeHook from '../../../hooks/ThemeHook';
import { useLocation } from 'react-router-dom';

const History = () => {
  const location = useLocation();
  const { state } = location;
  const { userIdFromParent = '' } = state || {};
  const { getHistory, deleteHistoryById } = HistoryApiHook();
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [deleteConfirmFlag, setDeleteConfirmFlag] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const { xs_layout } = useThemeHook();

  const {
    data: userArr,
    isLoading: userDataLoader,
    refetch: userListRefetch,
  } = useQuery(['GetUser'], () => getHistory(), {
    enabled: true,
  });

  const { mutate: deleteUserMutate, isLoading: deleteUserLoader } = useMutation(
    deleteHistoryById,
    {
      onSuccess: (data) => onSuccessRegister(data),
      onError: (data) => onErrorResgister(data),
    }
  );

  const onSuccessRegister = (data) => {
    if (data.data.status) {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message ?? 'History deleted successfully',
        type: 'success',
      });
      userListRefetch();
    } else {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'error',
      });
    }
  };

  const onErrorResgister = (data) => {
    console.log('data', data);
  };

  const userData = userArr?.data?.data?.data ?? [];

  const handleCancel = () => {
    setDeleteConfirmFlag(false);
  };

  const handleOk = () => {
    setDeleteConfirmFlag(false);
    deleteUserMutate(currentId);
  };

  return (
    <AuthLayout>
      {deleteUserLoader ||
        (userDataLoader && (
          <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        ))}
      <Box sx={{ width: xs_layout ? '100%' : '90%', margin: '20px auto' }}>
        <MaterialTable
          columns={[
            {
              title: 'HistroyId',
              field: 'playhistoryId',
              cellStyle: { width: 10 },
            },
            {
              title: 'UId',
              field: 'userId',
              cellStyle: { width: 10 },
              // defaultGroupOrder: 0,
            },
            { title: 'QId', field: 'qId', cellStyle: { width: 10 } },
            {
              title: 'Date',
              field: 'date',
              cellStyle: { width: 330 },
            },
            {
              title: 'Bal',
              field: 'amount',
              cellStyle: { width: 50 },
            },
            {
              title: 'Bet',
              field: 'multiplier',
              render: (rowData) => `${rowData.multiplier}X`,
              cellStyle: { width: 50 },
            },
            {
              title: 'Points',
              field: 'betPoints',
              cellStyle: { width: 50 },
            },
            { title: 'Status', field: 'status', cellStyle: { width: 50 } },
          ]}
          data={userData}
          title="Playing History"
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh',
              isFreeAction: true,
              onClick: (event) => {
                userListRefetch();
              },
            },
            (rowData) => ({
              icon: 'delete',
              tooltip: 'Delete User',
              onClick: (event, rowData) => {
                setDeleteConfirmFlag(true);
                setCurrentId(rowData.playhistoryId);
              },
            }),
          ]}
          options={{
            tableLayout: 'auto',
            actionsColumnIndex: 0,
            grouping: true,
            pageSize: 20,
            search: true,
            searchText: '' + userIdFromParent,
          }}
        />
      </Box>

      {deleteConfirmFlag && (
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth="xs"
          open={true}
        >
          <DialogTitle>Do you want to delete this History?</DialogTitle>
          <DialogContent dividers>
            <Typography fontWeight="700">History Id {currentId}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Ok</Button>
          </DialogActions>
        </Dialog>
      )}
      {Object.keys(snakeBarProps).length > 0 && (
        <CustomSnackbar
          {...snakeBarProps}
          setSnakeBarProps={setSnakeBarProps}
        />
      )}
    </AuthLayout>
  );
};

export default History;
