import { Grid } from '@mui/material';
import React, { useState } from 'react';
import Sidebar from './shared/Sidebar';
import { Header } from './shared/Header';
import { FormControl, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomAutoComplete } from '../../components/CustomAutoComplete';
import { SideMenu } from '../../models/SideMenu';
import { useNavigate } from 'react-router-dom';
import useThemeHook from '../../hooks/ThemeHook';

export default function AuthLayout(props) {
  const { xs_layout } = useThemeHook();
  const navigate = useNavigate();

  const [sideMenuValue, setsideMenuValue] = useState('');
  const [sideMenuObj, setsideMenuObj] = useState({});

  return (
    <Grid container style={{ backgroundColor: '#F7F8F9', height: '100%' }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Header />
      </Grid>
      <Grid container item style={{ minHeight: 'calc(100vh - 89px)' }}>
        {/* {xs_layout && (
          <Grid item xs={12} style={{ marginTop: 10, paddingInline: 8 }}>
            <FormControl fullWidth>
              <CustomAutoComplete
                id="Choose page"
                required
                options={SideMenu}
                getoptionlabelkey="label"
                selectedvalue={sideMenuObj}
                onChange={(_event, newData) => {
                  setsideMenuObj(newData);
                  setsideMenuValue(newData?.label);
                  navigate(newData.route);
                }}
              />
            </FormControl>
          </Grid>
        )} */}
        <Grid item xs={xs_layout ? 12 : 12} justifyContent="center">
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
}
