import { Box, Grid, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../components/Button';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { CustomTextField } from '../../components/TextField';
import { afterValidate } from '../../utils/CommonLogic';
import { getErrorMsz } from '../../utils/validator';
import { responsiveStype } from '../../theme/responsive';
import { useMutation } from 'react-query';
import useUnAuthCrudApiHook from '../../hooks/UnAuthApiHook';
import { updateState } from '../../redux/commonSlice';
import { useDispatch } from 'react-redux';
import { UnAuthLayout } from './UnAuthLayout';

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snakeBarProps, setSnakeBarProps] = useState({});

  const [submitFlag, setsubmitFlag] = useState(false);
  const [pageData, setPageData] = useState({ mobilenumber: '' });
  const { signIn } = useUnAuthCrudApiHook();

  const { mutate: loginMutate, isLoading } = useMutation(signIn, {
    onSuccess: (data) => onSuccessLogin(data),
    onError: (data) => onErrorLogin(data),
  });

  const onSuccessLogin = (data) => {
    //   console.log('data---', data);
    // data.data.data.accessToken &&
    //   setAsyncStorageData('accessToken', data.data.data.accessToken);
    dispatch(updateState(data.data.data));
    // updateFcmToken(data.data.data, 'login');
    navigate('/admincontrol/mobileverification');
  };

  const onErrorLogin = (data) => {
    // console.log('error---', error);
  };

  const submitHandler = () => {
    setsubmitFlag(true);
    afterValidate(afterValidateCallBack);
  };

  const afterValidateCallBack = (second) => {
    loginMutate({ mobilenumber: pageData.mobilenumber });
  };

  return (
    <UnAuthLayout>
      <Grid container justifyContent="center" alignItems="center">
        <Grid xs={12}>
          <Typography variant="h3">
            <Box
              fontWeight="bold"
              mb={2}
              mt={6}
              sx={responsiveStype.Login.Typo}
            >
              Log In
            </Box>
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="body2" color="primary">
            <Box mb={3} sx={responsiveStype.Login.Typo}>
              Existing User? Enter your login details below
            </Box>
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Box mb={2} width={1}>
            <CustomTextField
              placeholder="Phone Number"
              variant="filled"
              fullWidth
              endIcon={<img src="./images/flag.png" />}
              required
              error={
                submitFlag &&
                getErrorMsz('phone_number', pageData.mobilenumber) != ''
              }
              errorMsz={getErrorMsz('phone_number', pageData.mobilenumber)}
              onChange={(event) => {
                setPageData({
                  ...pageData,
                  mobilenumber: event.target.value,
                });
              }}
            />
          </Box>
        </Grid>

        {/* <Grid xs={12}>
          <Box mb={3} width={1} textAlign="right" sx={{ fontSize: '12px' }}>
            <Link
              href=""
              underline="always"
              color="inherit"
              onClick={() => navigate('/signup')}
            >
              New User, Register?
            </Link>
          </Box>
        </Grid> */}
        <Grid xs={16} sx={responsiveStype.Login.Button}>
          <CustomButton
            btnText="Log in"
            color="primary"
            variant="contained"
            className="minWidth240"
            onClick={submitHandler}
          />
        </Grid>
      </Grid>

      {
        // Object.keys(snakeBarProps).length > 0 &&
        <CustomSnackbar
          {...snakeBarProps}
          setSnakeBarProps={setSnakeBarProps}
        />
      }
    </UnAuthLayout>
  );
}
