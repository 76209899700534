import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CustomAutoComplete } from './CustomAutoComplete';
import { filterQuesArr } from '../constant';

export const SelectedDropdown = (props) => {
  const {
    subjectTrue,
    topicTrue,
    classTrue,
    scqTrue,
    pagedata,
    setPagedata,
    entryPoint,
    duration,
    status,
    level,
  } = props;

  return (
    <Box>
      <Grid container alignItems={'center'} spacing={2}>
        {/* <Grid xs='12' lg='2'>
                <Typography>
                    Your Question List
                </Typography>
            </Grid> */}
        {subjectTrue && (
          <Grid item xs="12" sm="12" md="4" lg="4">
            <CustomAutoComplete
              id="Course"
              required
              options={filterQuesArr?.courses}
              getoptionlabelkey="value"
              selectedvalue={pagedata.courseObject}
              onChange={(_event, newData) => {
                setPagedata({
                  ...pagedata,
                  courseObject: newData,
                  courses: newData?.value,
                });
              }}
            />
          </Grid>
        )}
        {topicTrue && (
          <Grid item xs="12" sm="12" md="4" lg="4">
            <CustomAutoComplete
              id="Topic"
              required
              options={filterQuesArr?.topics}
              getoptionlabelkey="value"
              selectedvalue={pagedata.topicObject}
              onChange={(_event, newData) => {
                setPagedata({
                  ...pagedata,
                  topicObject: newData,
                  topics: newData?.value,
                });
              }}
            />
          </Grid>
        )}
        {classTrue && (
          <Grid item xs="12" sm="12" md="4" lg="4">
            <CustomAutoComplete
              id="Class"
              required
              options={filterQuesArr?.standards}
              getoptionlabelkey="value"
              selectedvalue={pagedata.standardObject}
              onChange={(_event, newData) => {
                setPagedata({
                  ...pagedata,
                  standardObject: newData,
                  standards: newData?.value,
                });
              }}
            />
          </Grid>
        )}
        {scqTrue && (
          <Grid item xs="12" sm="12" md="4" lg="4">
            <CustomAutoComplete
              id="Scq"
              required
              options={filterQuesArr?.choice}
              getoptionlabelkey="value"
              selectedvalue={pagedata.choiceObject}
              onChange={(_event, newData) => {
                setPagedata({ ...pagedata, choiceObject: newData });
              }}
            />
          </Grid>
        )}
        {entryPoint && (
          <Grid item xs="12" sm="12" md="4" lg="4">
            <CustomAutoComplete
              id="Entry Points"
              required
              options={filterQuesArr?.entryPointsArray}
              getoptionlabelkey="value"
              selectedvalue={pagedata.entryPointObject}
              onChange={(_event, newData) => {
                setPagedata({
                  ...pagedata,
                  entryPointObject: newData,
                  entryPoints: newData.value,
                });
              }}
            />
          </Grid>
        )}
        {duration && (
          <Grid item xs="12" sm="12" md="4" lg="4">
            <CustomAutoComplete
              id="Duration"
              required
              options={filterQuesArr?.durationArray}
              getoptionlabelkey="value"
              selectedvalue={pagedata.durationObject}
              onChange={(_event, newData) => {
                setPagedata({
                  ...pagedata,
                  durationObject: newData,
                  duration: newData.value,
                });
              }}
            />
          </Grid>
        )}
        {level && (
          <Grid item xs="12" sm="12" md="4" lg="4">
            <CustomAutoComplete
              id="level"
              required
              options={filterQuesArr?.level}
              getoptionlabelkey="value"
              selectedvalue={pagedata.levelObject}
              onChange={(_event, newData) => {
                setPagedata({
                  ...pagedata,
                  levelObject: newData,
                  level: newData.value,
                });
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
