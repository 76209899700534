import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { SideMenu } from '../../../models/SideMenu';
import { useNavigate } from 'react-router-dom';
import useThemeHook from '../../../hooks/ThemeHook';

const Sidebar = () => {
  const navigate = useNavigate();
  const { xs_layout } = useThemeHook();

  return (
    <Box
      style={{ textAlign: 'center' }}
      pt={2}
      pb={xs_layout ? 0 : 2}
      pl={xs_layout ? 0 : 5}
    >
      {SideMenu.map((o) => {
        return (
          <Box
            xs={12}
            lg={12}
            style={{
              display: 'inline-block',
              cursor: 'pointer',
              width: 'auto',
            }}
            mr={3}
            onClick={() => navigate(o.route)}
          >
            <Typography
              variant="span"
              sx={{ color: '#FCBD42', fontWeight: '600' }}
            >
              {o.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default Sidebar;
