import { useMutation } from 'react-query';
import QuestionApiHook from './QuestionApiHook';

const QuestionJsonApiHook = ({ questionListRefech }) => {
  const { createQuestion, updateQuestionById, uploadImage } = QuestionApiHook();

  const initialObj = {
    courses: 'All',
    topics: 'All',
    standards: 'All',
    entryPoints: '100',
    duration: 12,
    questionType: 'Text',
    questionLabel: '',
    question: '',
    questionImage: '',
    questionAudio: '',
    questionOptions: [
      {
        label: 'Option 1',
        selected: false,
        option: '',
      },
      {
        label: 'Option 2',
        selected: false,
        option: '',
      },
      {
        label: 'Option 3',
        selected: false,
        option: '',
      },
      {
        label: 'Option 4',
        selected: true,
        option: '',
      },
    ],
    questionChoiceType: 'SCQ',
  };

  const onSuccessRegister = (data) => {
    questionListRefech();
  };
  const onErrorResgister = (data) => {};

  const { mutate: createQuestionMutate, isLoading: deleteUserLoader } =
    useMutation(createQuestion, {
      onSuccess: onSuccessRegister,
      onError: onErrorResgister,
    });

  const readFileFromJSON = (data) => {
    const fileReader = new FileReader();
    fileReader?.readAsText(data[0], 'UTF-8');
    fileReader.onload = (e) => {
      const result = JSON.parse(e.target.result);
      // const localArr =
      result.forEach((o) => {
        createQuestionMutate({
          ...initialObj,
          question: o.question,
          status: 'Inactive',
          questionOptions: initialObj.questionOptions.map((p, index) => ({
            ...p,
            option: o['option' + (index + 1)],
            selected: o['option' + (index + 1)] == o['answer'],
          })),
        });
      });
    };
  };
  return {
    readFileFromJSON,
  };
};
export default QuestionJsonApiHook;
