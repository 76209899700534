export const buttonStyle = {
  MuiButtonBase: {
    styleOverrides: {
      root: {
        borderRadius: '50px !important',
        padding: '5px !important',
        '&.minWidth240': {
          minWidth: '240px !important',
          fontWeight: '700',
          fontSize: '20px',
          fontFamily: 'Urbantist',
        },
      },
    },
  },
};
