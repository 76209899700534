export const textFieldStyle = {
  MuiTextField: {
    styleOverrides: {
      // Name of the slot
      root: {
        backgroundColor: '#F7F8F9',
        borderRadius: '10px !important',
        fullWidth: {
          width: '100%',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: '10px !important',
      },
      input: {
        borderRadius: '10px !important',
        backgroundColor: '#F7F8F9 !important',
        padding: '15px 10px !important',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        marginTop: '0px !important',
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: '#F7F8F9 !important',
        '&:after,&:before': {
          border: 'none !important',
        },
      },
    },
  },
};
