import axios from 'axios';
import {
  clearAsyncStorageData,
  getAsyncStorageData,
  logout,
} from '../utils/CommonLogic';
import { useDispatch } from 'react-redux';
import { resetState } from '../redux/commonSlice';
// import { useNavigation } from 'react-router-dom';

const useRequestHook = () => {
  // const navigation = useNavigation();
  const dispatch = useDispatch();
  const Request = axios.create({
    // baseURL: 'http://10.0.2.2:7000',
    baseURL: 'http://192.168.1.2:7001',
    // baseURL: 'https://quiz.digiappstore.com'
  });

  Request.interceptors.request.use(
    async (config) => {
      const token = await getAsyncStorageData('accessToken');

      config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      // if (config.data instanceof FormData) {
      //   config.headers['Content-Type'] = 'multipart/form-data';
      // } else {
      //   config.headers['Content-Type'] = 'application/json';
      // }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  Request.interceptors.response.use(
    (res) => res,
    async (err) => {
      // console.log('err', err.response.data);
      // const originalConfig = err.config;
      if (err.response) {
        if (err.response.status === 401) {
          dispatch(resetState());
          clearAsyncStorageData();
          window.location.href = '/admincontrol';
          // useAuthLogin.logout();
          // logout(navigation, dispatch);
          // originalConfig._retry = true;
          // try {
          //   const rs = await refreshToken();
          //   const { accessToken } = rs.data;
          //   var ls = JSON.parse(window.localStorage['persist:quiz']);
          //   ls.accessToken = accessToken;
          //   window.localStorage.setItem('persist:quiz', JSON.stringify(ls));
          //   Request.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          //   return Request(originalConfig);
          // } catch (_error) {
          //   // console.log('first', _error);
          //   window.location.href = '/';
          // }
        }

        if (err.response.status === 403 && err.response.data) {
          return Promise.reject(err.response?.data);
        }
      }

      return Promise.reject(err.response?.data);
    }
  );
  return {
    Request,
  };
};

export default useRequestHook;
