import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Backdrop, Box, Grid, Switch } from '@material-ui/core';
import { CircularProgress, Typography } from '@mui/material';
import useConfigrutionAPIHook from '../../../hooks/ConfigrutionAPIHook';
import AuthLayout from '../AuthLayout';
import ChipBox from './ChipBox';
import ChipBoxInput from './ChipBoxInput';
import { CustomButton } from '../../../components/Button';
import { CustomTextField } from '../../../components/TextField';

function Configuration() {
  const [configData, setConfigData] = useState([]);
  const chipDataArr = [
    'courses',
    'topics',
    'standards',
    'choice',
    'entryPointsArray',
    'durationArray',
    'statusArray',
  ];
  const chipInputDataArr = [
    // 'durationValue',
    // 'defaultQuestionPointsValue',
    'version',
    'initialUserPoints',
    // 'minDeposit',
    // 'minWithdrawal',
    'defaultPlan',
    'defaultQuestionAttemptLimit',
  ];

  const { getConfig, updateConfig } = useConfigrutionAPIHook();
  const {
    data: configArr,
    isLoading: configLoader,
    refetch: configRefetch,
  } = useQuery(['getConfig'], () => getConfig(), {
    enabled: true,
  });

  const onSuccessConfig = () => {
    configRefetch();
  };
  const onErrorResgister = (error) => {
    console.warn(error);
  };
  const { mutate: updateConfigByIdMutate, isLoading: updateConfigLoader } =
    useMutation(updateConfig, {
      onSuccess: (data) => onSuccessConfig(data),
      onError: (data) => onErrorResgister(data),
    });

  useEffect(() => {
    if (configArr) {
      setConfigData(configArr?.data?.data);
    }
  }, [configArr]);

  const update = () => {
    updateConfigByIdMutate(configData);
  };

  const changeDefaultDuration = (event, type) => {
    setConfigData({ ...configData, [type]: event.target.checked });
  };

  return (
    <AuthLayout>
      {(configLoader || updateConfigLoader) && (
        <Box sx={{ p: 2, position: 'absolute', zIndex: 1 }}>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      <Box style={{ marginBlock: 50 }}>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            spacing={2}
            style={{ padding: 16 }}
          >
            <Grid
              item
              xs={12}
              md={6}
              style={{ backgroundColor: '#fff !important' }}
            >
              {/* <Grid container item xs={12} style={{ marginBottom: 20 }}>
                <Grid item xs={12} md={2}>
                  <Box>
                    <Typography variant="body">Default Duration</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={10}>
                  {' '}
                  <Switch
                    checked={chipDataArr.defaultDuration}
                    onChange={(e) =>
                      changeDefaultDuration(e, 'defaultDuration')
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} style={{ marginBottom: 20 }}>
                <Grid item xs={12} md={2}>
                  <Box>
                    <Typography variant="body">
                      Default Question Points{' '}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={10}>
                  {' '}
                  <Switch
                    checked={chipDataArr.defaultQuestionPoints}
                    onChange={(e) =>
                      changeDefaultDuration(e, 'defaultQuestionPoints')
                    }
                  />
                </Grid>
              </Grid> */}
              {chipInputDataArr.map((o) => {
                return (
                  <ChipBoxInput
                    key={o}
                    dataArr={configData}
                    chipType={o}
                    setConfigData={setConfigData}
                  />
                );
              })}
              <Grid
                item
                xs={12}
                md={12}
                style={{ backgroundColor: '#fff !important' }}
              >
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="Subcription Plan"
                  placeholder="Subcription Plan"
                  variant="filled"
                  value={JSON.stringify(configData.subcriptionPlan)}
                  onChange={(event) => {
                    setConfigData({
                      ...configData,
                      subcriptionPlan: JSON.parse(event.target.value),
                    });
                  }}
                  multiline
                  maxRows={3}
                  style={{ backgroundColor: '#fff !important' }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ backgroundColor: '#fff !important' }}
              >
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="Point BasedQuestion"
                  placeholder="Point BasedQuestion"
                  variant="filled"
                  value={JSON.stringify(configData.pointBasedQuestion)}
                  onChange={(event) => {
                    setConfigData({
                      ...configData,
                      pointBasedQuestion: JSON.parse(event.target.value),
                    });
                  }}
                  multiline
                  maxRows={3}
                  style={{ backgroundColor: '#fff !important' }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ backgroundColor: '#fff !important' }}
              >
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="Point BasedQuestion1"
                  placeholder="Point BasedQuestion1"
                  variant="filled"
                  value={JSON.stringify(configData.pointBasedQuestion1)}
                  onChange={(event) => {
                    setConfigData({
                      ...configData,
                      pointBasedQuestion1: JSON.parse(event.target.value),
                    });
                  }}
                  multiline
                  maxRows={3}
                  style={{ backgroundColor: '#fff !important' }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ backgroundColor: '#fff !important' }}
              >
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="Point BasedQuestion2"
                  placeholder="Point BasedQuestion2"
                  variant="filled"
                  value={JSON.stringify(configData.pointBasedQuestion2)}
                  onChange={(event) => {
                    setConfigData({
                      ...configData,
                      pointBasedQuestion2: JSON.parse(event.target.value),
                    });
                  }}
                  multiline
                  maxRows={3}
                  style={{ backgroundColor: '#fff !important' }}
                />
              </Grid>

              <Box style={{ textAlign: 'center' }}>
                <CustomButton
                  variant="contained"
                  btnText="Update"
                  btnClass={{
                    backgroundColor: '#4545A5',
                    color: '#fff',
                    width: 100,
                    marginTop: 16,
                  }}
                  onClick={update}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {chipDataArr.map((o) => {
                return (
                  <ChipBox
                    key={o}
                    dataArr={configData}
                    chipType={o}
                    setConfigData={setConfigData}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </AuthLayout>
  );
}

export default Configuration;
