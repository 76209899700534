// export const apiUrl = '';
// export const apiUrl = 'http://192.168.1.1:7001';
// export const apiUrl = 'http://192.168.1.4:7001';
export const apiUrl = 'https://quiz.digiappstore.com';

// export const apiUrlPrefix = `${apiUrl}/api/v1`;

export const apiAuthConstant = {
  userLogin: apiUrl + '/user/login',
  userLoginPin: apiUrl + '/user/loginpin',
  userRegister: apiUrl + '/user/register',
  userRegisterPin: apiUrl + '/user/registerpin',
  user: apiUrl + '/user',
  allUser: apiUrl + '/user/all',
  emailVerify: apiUrl + '/email/verify',
};
export const apiConstant = {
  ...apiAuthConstant,
  s3Upload: apiUrl + '/fileupload/upload',
  s3images: apiUrl + '/question/filteroptions',
  questionFilteroptions: apiUrl + '/question/filteroptions',
  question: apiUrl + '/question',
  questionAll: apiUrl + '/question/all',
  questionRandom: apiUrl + '/question/random',
  quiz: apiUrl + '/quiz',
  quizPlayer: apiUrl + '/quiz/player',
  quizAll: apiUrl + '/quiz/all',
  inactive: apiUrl + '/quiz/inactive',
  config: apiUrl + '/config',
  notification: apiUrl + '/notification',
  history: apiUrl + '/history',
  playhistory: apiUrl + '/playhistory',
  transaction: apiUrl + '/transaction',
};
