export const subjectArray = [
  { id: '1', value: 'English' },
  { id: '2', value: 'Hindi' },
];

export const filterQuesArr: any = {
  courses: [
    { value: 'All' },
    { value: 'Maths' },
    { value: 'Science' },
    { value: 'English' },
    { value: 'Hindi' },
    { value: 'Computer' },
    { value: 'Social Science' },
    { value: 'Current Affair' },
    { value: 'Games' },
    { value: 'Cars' },
    { value: 'Bikes' },
    { value: 'Movies' },
    { value: 'Logical Reasoning' },
    { value: 'Coding' },
    { value: 'Literature' },
    { value: 'Geography' },
  ],
  topics: [
    { value: 'All' },
    { value: 'Logical Reasoning or Verbal Reasoning' },
    { value: 'Non – Verbal Reasoning' },
    { value: 'Word Problems' },
    { value: 'Graphs' },
    { value: 'Simplification' },
    { value: 'English Language' },
    { value: 'General Knowledge' },
    { value: 'Current Affairs' },
    { value: 'Computer Knowledge' },
  ],
  standards: [
    { value: 'All' },
    { value: 'Class 1-5' },
    { value: 'Class 6-10' },
    { value: 'Class 11-12' },
    { value: 'Competitive Exams' },
    { value: 'Programmer' },
  ],
  choice: [
    { value: 'Both' },
    { value: 'Single Choice Question' },
    { value: 'Multiple Choice Question' },
  ],
  entryPointsArray: [
    { value: '50' },
    { value: '100' },
    { value: '150' },
    { value: '200' },
    { value: '250' },
  ],
  durationArray: [
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '10' },
    { value: '12' },
    { value: '15' },
    { value: '18' },
    { value: '20' },
    { value: '25' },
    { value: '30' },
  ],
  level: [
    { value: 'Easy' },
    { value: 'Medium' },
    { value: 'Hard' },
    { value: 'Tuff' },
  ],
  statusArray: [{ value: 'Active' }, { value: 'Inactive' }],
  winningStatus: ['Pending', 'Win', 'Loose'],
  notificationStatus: ['New', 'Played', 'Expired'],
  notificationType: [
    {
      value: 'Wake up',
      body: 'Feeling bor, Earn the money with just one answer',
    },
    {
      value: 'Live Message',
      body: 'Insta Jawab invite you to play live',
    },
    {
      value: 'Remind before Expiry',
      body: 'You have pending live questions, play them before expiry',
    },
    {
      value: 'Balance Check',
      body: 'Your Wallet balance is low, Deposit points and start playing',
    },
    {
      value: 'Update',
      body: 'New version of Insta Jawab v{appversion} has been released, Please update the app',
    },
    {
      value: 'Result',
      body: 'Result of your attempted live question is out, please check your result',
    },
    {
      value: 'Common',
      body: '',
    },
  ],
  routeTo: [
    {
      value: 'Home',
      label: 'Home',
    },
    {
      value: 'Subscription',
      label: 'Subscription',
    },
    {
      value: 'Redeem',
      label: 'Redeem',
    },
  ],
};

export const stringConstant = {
  Live_Message: 'Live Message',
  Wake_Up: 'Wake up',
  Remind_Before_Expiry: 'Remind before Expiry',
  Balance_Check: 'Balance Check',
  Update: 'Update',
  Result: 'Result',
  Promo: 'Promo',
  Common: 'Common',

  track: {
    JustCreated: 'Just Created',
    Sent: 'Sent',
    Result: 'Result',
    Delete: 'Delete',
    Reminder: 'Reminder',
  },
};

export const listsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
