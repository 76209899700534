//Library import statements...
import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import MobileVerification from './pages/unAuth/MobileVerification';
import LoginPage from './pages/unAuth/LoginPage';
import User from './pages/auth/tables/User';
import History from './pages/auth/tables/History';
import Transaction from './pages/auth/tables/Transaction';
import QuestionTable from './pages/auth/tables/Question';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TnC from './pages/TnC';
import Refund from './pages/Refund';
import Notification from './pages/auth/tables/Notification';
import NotFound from './pages/NotFound';
import Webhook from './pages/Webhook';
import VerifyEmail from './pages/VerifyEmail';
import Configuration from './pages/auth/configrution';
import UserDetails from './pages/auth/UserDetails';

const App = () => {
  const ls = JSON.parse(window.localStorage.getItem('persist:root'));

  return (
    <Router>
      <Routes>
        {ls?.userId && JSON.parse(ls?.userId) == '' ? (
          <Route path="/admincontrol" element={<LoginPage />} />
        ) : (
          <Route path="/admincontrol" element={<MobileVerification />} />
        )}
        <Route
          path="/admincontrol/mobileverification"
          element={<MobileVerification />}
        />
        <Route path="/" element={<Home />} />
        <Route path="/notfound" element={<NotFound />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/payment/webhook" element={<Webhook />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/tnc" element={<TnC />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/refund-and-cancellation" element={<Refund />} />
        <Route path="/admincontrol/userdetails" element={<UserDetails />} />
        <Route path="/admincontrol/history" element={<History />} />
        <Route path="/admincontrol/transaction" element={<Transaction />} />
        <Route path="/admincontrol/user" element={<User />} />
        <Route path="/admincontrol/question" element={<QuestionTable />} />
        <Route path="/admincontrol/configuration" element={<Configuration />} />
        <Route path="/admincontrol/notification" element={<Notification />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
