import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomButton } from '../../components/Button';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { CustomOtp } from '../../components/otp';
import { afterValidate } from '../../utils/CommonLogic';
import { UnAuthLayout } from './UnAuthLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import useUnAuthCrudApiHook from '../../hooks/UnAuthApiHook';
import { updateState } from '../../redux/commonSlice';
import { Backdrop } from '@material-ui/core';

const MobileVerification = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const commonReducer = useSelector((state) => state.commonReducer);

  const { userId } = commonReducer;
  const [submitFlag, setsubmitFlag] = useState(false);
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const { signUpWithPin, signInWithPin } = useUnAuthCrudApiHook();
  const [otp, setOtp] = useState();
  const [hasErrored, setHasErrored] = useState(false);
  const [errorMsz, setErrorMsz] = useState('');
  const [loader, setLoader] = useState(false);

  const handleChangeOtp = (value) => {
    setOtp(value);

    if (userId) {
      if (value.length == 4) {
        userId
          ? signUpWithPinMutate({
              password: value,
              userId: parseInt(userId),
            })
          : signInWithPinMutate({
              password: value,
              userId: parseInt(userId),
            });
      }
    } else {
      window.location.reload();
    }
  };

  const submitHandler = () => {
    setHasErrored(otp == undefined ? true : otp.length == 4 ? false : true);
    setErrorMsz(
      otp == undefined ? 'Enter Otp' : otp.length == 4 ? '' : 'Enter empty otp'
    );
    if (otp != undefined && otp.length == 4) {
      setsubmitFlag(true);
      afterValidate(afterValidateCallBack);
    }
  };

  const afterValidateCallBack = (second) => {
    if (otp.length == 4) {
      signUpWithPinMutate({
        password: otp,
        userId: parseInt(userId),
      });
    }
  };

  const onErrorLogin = () => {
    setsubmitFlag(false);
  };
  const onErrorSignInLogin = () => {
    setsubmitFlag(false);
    // setResetPin(true);
  };
  const onSuccessUpLogin = (data, variable) => {
    setsubmitFlag(false);
    signInWithPinMutate({
      password: variable.password,
      userId: variable.userId,
    });
  };
  const onSuccessInLogin = (data) => {
    setLoader(true);
    setsubmitFlag(false);
    if (data.data.data.role === 'admin') {
      dispatch(updateState(data.data.data));
      setTimeout(() => {
        setLoader(false);
        navigate('/admincontrol/userdetails');
      }, 1000);
    } else {
      navigate('/notfound');
    }
  };

  const { mutate: signUpWithPinMutate, isLoading: signUpWithPinLoader } =
    useMutation(signUpWithPin, {
      onSuccess: onSuccessUpLogin,
      onError: onErrorLogin,
    });

  const { mutate: signInWithPinMutate, isLoading: signInWithPinLoader } =
    useMutation(signInWithPin, {
      onSuccess: (data) => onSuccessInLogin(data),
      onError: onErrorSignInLogin,
    });

  return (
    <UnAuthLayout>
      {signUpWithPinLoader || signInWithPinLoader || loader ? (
        <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      ) : (
        <Grid container mt={4} justifyContent="center" alignItems="center">
          <Grid xs={12}>
            <Typography variant="h4" textAlign="center">
              <Box mb={1} fontWeight="bold">
                Hi,{' '}
                {commonReducer.username == ''
                  ? 'Guest'
                  : commonReducer.username}
              </Box>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h4" textAlign="center">
              <Box mb={1} fontWeight="bold">
                Enter your Insta Jawab PIN
              </Box>
            </Typography>
          </Grid>

          <Grid xs={12}>
            <Box style={{ margin: '50px auto' }}>
              <CustomOtp
                value={otp}
                handleChange={handleChangeOtp}
                hasErrored={hasErrored}
                errorMsz={errorMsz}
              />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box mb={2} textAlign="center">
              <CustomButton
                btnText="Reset Pin"
                color="primary"
                variant="text"
                className="minWidth240"
                onClick={() => (submitFlag ? {} : submitHandler())}
                endIcon={
                  submitFlag ? <CircularProgress color="success" /> : null
                }
              />
            </Box>
          </Grid>

          {Object.keys(snakeBarProps).length > 0 && (
            <CustomSnackbar
              {...snakeBarProps}
              setSnakeBarProps={setSnakeBarProps}
            />
          )}
        </Grid>
      )}
    </UnAuthLayout>
  );
};

export default MobileVerification;
