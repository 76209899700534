import { createSlice } from '@reduxjs/toolkit';

const initialValue = {
  accessToken: '',
  refreshToken: '',
  mobilenumber: '',
  initialPoints: null,
  username: '',
  userId: '',
  totalPoints: null,
  configurations: [],
  email: '',
};

export const commonSlice = createSlice({
  name: 'commonlo',
  initialState: initialValue,
  reducers: {
    updateState: (state, action) => {
      assignValueInState(action.payload, state);
    },
    updateObjState: (state, action) => {
      return { ...state, ...action.payload };
    },
    // resetFilterState: (state) => {
    //     assignValueInState(filterInitialValue, state.filterTabObj);
    // },
    resetState: (state) => {
      return assignValueInState(initialValue, state);
    },
  },
});

const assignValueInState = (obj, state) => {
  for (const key in obj) {
    state[key] = obj[key];
  }
};

export const { updateState, resetState, updateObjState } = commonSlice.actions;
export default commonSlice.reducer;

// dispatch(updateState({ value: 25 }))
// dispatch(resetState())

// const commonReducer = useSelector((state) => state.commonReducer);
