import { Box, Checkbox, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { filterQuesArr, stringConstant } from '../../../constant';
import { CustomTextField } from '../../../components/TextField';
import { CustomButton } from '../../../components/Button';
import { useMutation, useQuery } from 'react-query';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import { Backdrop, CircularProgress, FormControl } from '@material-ui/core';
import { CustomAutoComplete } from '../../../components/CustomAutoComplete';
import CustomDateTimePicker from '../../../components/CustomDateTimePicker';
import UserApiHook from '../../../hooks/UserApiHook';
import dayjs from 'dayjs';
import moment from 'moment';
import useNotificationApiHook from '../../../hooks/NotificationApiHook';
import useConfigrutionAPIHook from '../../../hooks/ConfigrutionAPIHook';

export const CreateNotification = (props) => {
  const { preview, setToggleDrawer, questionListRefech, currentQuestionData } =
    props;

  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [userArrData, setUserArrData] = React.useState([]);
  const format = 'YYYY-MM-DDTHH:mm:ss';

  const { getUser } = UserApiHook();
  const { updateNotificationById, createNotification } =
    useNotificationApiHook();
  const { getConfig, updateConfig } = useConfigrutionAPIHook();

  const initialObj = {
    activeStatus: 'InActive',
    notificationTypeObject: filterQuesArr.notificationType[0],
    notificationType: filterQuesArr.notificationType[0].value,
    routeToObject: {},
    // routeToObject: filterQuesArr.routeTo[0],
    title: 'Instajawab : Quick Reward Challenge',
    body: filterQuesArr.notificationType[0].body,
    remoteData: {
      routeTo: '',
      betAmount: '200',
      expiryTime: dayjs(new Date()),
      questionLabel: '',
      question: '',
      appVersion: '',
      questionOptions: [
        {
          label: 'Option 1',
          selected: false,
          option: '',
        },
        {
          label: 'Option 2',
          selected: false,
          option: '',
        },
        {
          label: 'Option 3',
          selected: false,
          option: '',
        },
        {
          label: 'Option 4',
          selected: false,
          option: '',
        },
      ],
    },
  };

  const [pagedata, setPagedata] = useState(initialObj);
  const [showPreview, setShowPreview] = useState(preview ?? false);
  const [previewError, setPreviewError] = useState(false);

  useEffect(() => {
    if (Object.keys(currentQuestionData).length > 0) {
      setPagedata({
        ...initialObj,
        ...currentQuestionData,
        remoteData: {
          ...currentQuestionData.remoteData,
          expiryTime: dayjs(currentQuestionData?.remoteData?.expiryTime),
          routeToObject:
            filterQuesArr?.routeTo.filter(
              (o) => o.value === currentQuestionData?.routeTo
            )[0] ?? {},
        },
        notificationTypeObject: filterQuesArr?.notificationType.filter(
          (o) => o.value === currentQuestionData.notificationType
        )[0],
      });
    } else {
      setPagedata(initialObj);
    }
  }, [currentQuestionData]);

  const {
    data: userArr,
    isLoading: userDataLoader,
    refetch: userListRefetch,
  } = useQuery(['GetUser'], () => getUser(), {
    enabled: true,
  });

  useEffect(() => {
    if (userArr) {
      setUserArrData(userArr?.data?.data?.data);
    }
  }, [userArr]);

  const {
    data: configArr,
    isLoading: configLoader,
    refetch: configRefetch,
  } = useQuery(['getConfig'], () => getConfig(), {
    enabled: true,
  });

  const configDataArr = configArr?.data?.data || [];
  const {
    mutate: createNotificationMutate,
    isLoading: createNotificationLoader,
  } = useMutation(createNotification, {
    onSuccess: (data) => onSuccessRegister(data),
    onError: (data) => onErrorResgister(data),
  });

  const {
    mutate: updateNotificationByIdMutate,
    isLoading: updateQuestionByIdLoader,
  } = useMutation(updateNotificationById, {
    onSuccess: (data) => onSuccessRegister(data),
    onError: (data) => onErrorResgister(data),
  });

  const { mutate: updateConfigByIdMutate, isLoading: updateConfigLoader } =
    useMutation(updateConfig, {
      onSuccess: (data) => onSuccessConfig(data),
      onError: (data) => onErrorResgister(data),
    });

  const createNotificationHandler = () => {
    const flag =
      pagedata.notificationType == stringConstant.Common ||
      pagedata.notificationType == stringConstant.Update ||
      pagedata.notificationType == stringConstant.Live_Message ||
      pagedata.notificationType == stringConstant.Remind_Before_Expiry;
    createNotificationMutate({
      ...pagedata,
      remoteData: flag ? pagedata.remoteData : {},
      createAt: moment().format(format),
      updatedAt: moment().format(format),
      track: 'Just Created',
      remindCount: 0,
    });
  };
  const updateNotificationHandler = () => {
    updateConfigByIdMutate({ version: pagedata.remoteData.appVersion });
  };

  const onErrorResgister = (error) => {
    console.log('error', error);
  };

  const onSuccessRegister = (data) => {
    if (data.data.status) {
      questionListRefech && questionListRefech();
      setToggleDrawer && setToggleDrawer(false);
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'success',
      });
    } else {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'error',
      });
    }
  };

  const onSuccessConfig = () => {
    updateNotificationByIdMutate({
      notificationId: currentQuestionData.notificationId,
      data: {
        ...pagedata,
        expiryTime: dayjs(pagedata.expiryTime).format('YYYY-MM-DDTHH:mm:ss'),
        updatedAt: moment().format(format),
      },
    });
  };

  const checkboxClickHandler = (index, event) => {
    const localArray = pagedata?.remoteData.questionOptions?.map(
      (item, currentIndex) => {
        if (
          pagedata.remoteData.questionType == 'Text' &&
          pagedata.remoteData.questionChoiceType == 'MCQ'
        ) {
          return index === currentIndex
            ? { ...item, selected: event.target.checked }
            : item;
        } else if (
          pagedata.remoteData.questionType == 'Image' &&
          pagedata.remoteData.questionChoiceType == 'MCQ'
        ) {
          return index === currentIndex
            ? { ...item, selected: event.target.checked }
            : item;
        } else {
          return {
            ...item,
            selected: index === currentIndex ? event.target.checked : false,
          };
        }
      }
    );
    setPagedata({
      ...pagedata,
      remoteData: { ...pagedata.remoteData, questionOptions: localArray },
    });
  };

  const textFiledClickHandler = (index, event) => {
    const localArray = pagedata?.remoteData.questionOptions?.map(
      (item, currentIndex) => {
        return index === currentIndex
          ? { ...item, option: event.target.value }
          : item;
      }
    );
    setPagedata({
      ...pagedata,
      remoteData: { ...pagedata.remoteData, questionOptions: localArray },
    });
  };

  const showPreviewHandler = () => {
    let errorFlag = false;
    if (
      pagedata.notificationType == stringConstant.Live_Message ||
      pagedata.notificationType == stringConstant.Remind_Before_Expiry
    ) {
      const answerArr = pagedata.remoteData.questionOptions.map(
        (o) => o.selected
      );
      const checkAnswer = answerArr.every((o) => !o);
      if (
        pagedata.body == '' ||
        pagedata.title == '' ||
        pagedata.remoteData.betAmount == '' ||
        pagedata.remoteData.questionLabel == '' ||
        pagedata.remoteData.question == '' ||
        // localUserIdArr.length === 0 ||
        checkAnswer
      ) {
        errorFlag = true;
      }
    }
    if (
      pagedata.notificationType == stringConstant.Update &&
      pagedata.remoteData.appVersion == ''
    ) {
      errorFlag = true;
    }
    if (
      (pagedata.notificationType == stringConstant.Common &&
        pagedata.body == '') ||
      (pagedata.notificationType == stringConstant.Common &&
        pagedata.remoteData.routeTo == '')
    ) {
      errorFlag = true;
    }
    setPreviewError(errorFlag);
    !errorFlag && setShowPreview(true);
  };

  return (
    // <AuthLayout>
    <>
      {(updateQuestionByIdLoader ||
        createNotificationLoader ||
        userDataLoader) && (
        <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}

      <Box p={1} height={1}>
        <Typography variant="h6">
          {' '}
          {showPreview ? 'Notification Preview' : 'Add Notification'}
        </Typography>
        <Divider variant="fullWidth" />

        <Grid container spacing={2} justifyContent="center" style={{}}>
          {!showPreview ? (
            <>
              <Grid container item xs={12}>
                <Grid container item xs={12}>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      <Box>
                        <Grid container>
                          <Grid item xs={12} style={{ marginTop: 10 }}>
                            <FormControl fullWidth>
                              <CustomAutoComplete
                                inputLabel="Notification Status"
                                id="notificationType"
                                required
                                options={filterQuesArr?.notificationType}
                                getoptionlabelkey="value"
                                selectedvalue={pagedata.notificationTypeObject}
                                onChange={(_event, newData) => {
                                  setPagedata({
                                    ...pagedata,
                                    notificationTypeObject: newData,
                                    notificationType: newData?.value,
                                    body: newData?.body,
                                  });
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 10 }}>
                            <CustomTextField
                              inputLabel="Title"
                              fullWidth
                              type="text"
                              multiline
                              placeholder="Notification Title"
                              onChange={(e) => {
                                setPagedata({
                                  ...pagedata,
                                  title: e.target.value,
                                });
                              }}
                              className="question-textfield"
                              value={pagedata.title}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <CustomTextField
                              inputLabel="Body"
                              fullWidth
                              type="text"
                              multiline
                              placeholder="Notification Body"
                              onChange={(e) => {
                                setPagedata({
                                  ...pagedata,
                                  body: e.target.value,
                                });
                              }}
                              className="question-textfield"
                              value={
                                pagedata.notificationType ==
                                stringConstant.Update
                                  ? pagedata.body.replace(
                                      '{appversion}',
                                      pagedata.remoteData.appVersion
                                    )
                                  : pagedata.body
                              }
                            />
                          </Grid>
                          {pagedata.notificationType ==
                            stringConstant.Common && (
                            <Grid item xs={12} style={{ marginTop: 10 }}>
                              <FormControl fullWidth>
                                <CustomAutoComplete
                                  inputLabel="Route to"
                                  id="routeTo"
                                  required
                                  options={filterQuesArr?.routeTo}
                                  getoptionlabelkey="value"
                                  selectedvalue={pagedata?.routeToObject}
                                  onChange={(_event, newData) => {
                                    setPagedata({
                                      ...pagedata,
                                      routeToObject: newData,
                                      remoteData: {
                                        ...pagedata.remoteData,
                                        routeTo: newData?.value,
                                      },
                                    });
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={1}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item xs={7}>
                    <Grid
                      item
                      xs="12"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height:
                          pagedata.notificationType == stringConstant.Update ||
                          pagedata.notificationType ==
                            stringConstant.Live_Message ||
                          pagedata.notificationType ==
                            stringConstant.Remind_Before_Expiry
                            ? 'auto'
                            : '100%',
                      }}
                    >
                      <Typography
                        style={{ textAlign: 'center', marginTop: 20 }}
                      >
                        Total User{':  '}
                        {userArrData.length}
                      </Typography>
                      {pagedata.notificationType ==
                        stringConstant.Remind_Before_Expiry && (
                        <Typography
                          style={{ textAlign: 'center', marginBottom: 20 }}
                        >
                          User not player live Question yet{':  '}
                          {
                            userArrData.filter((o) => o.winningStatus == 'New')
                              .length
                          }
                        </Typography>
                      )}
                      {pagedata.notificationType ==
                        stringConstant.Balance_Check && (
                        <Typography
                          style={{ textAlign: 'center', marginBottom: 20 }}
                        >
                          User count for Balance{configDataArr?.lowBalanceRange}
                          {':  '}
                          {
                            userArrData.filter(
                              (o) => o.points <= configDataArr?.lowBalanceRange
                            ).length
                          }
                        </Typography>
                      )}
                    </Grid>
                    {(pagedata.notificationType ==
                      stringConstant.Live_Message ||
                      pagedata.notificationType ==
                        stringConstant.Remind_Before_Expiry) && (
                      <>
                        <Grid item xs="12">
                          <CustomTextField
                            inputLabel="Question Label"
                            fullWidth
                            type="text"
                            placeholder="Question Label"
                            onChange={(e) => {
                              setPagedata({
                                ...pagedata,
                                remoteData: {
                                  ...pagedata.remoteData,
                                  questionLabel: e.target.value,
                                },
                              });
                            }}
                            value={pagedata.remoteData.questionLabel}
                            style={{ marginBottom: 10 }}
                          />
                        </Grid>
                        <Grid item xs="12">
                          <CustomTextField
                            fullWidth
                            inputLabel="Question"
                            type="text"
                            multiline
                            placeholder="Start writing your question"
                            onChange={(e) => {
                              setPagedata({
                                ...pagedata,
                                remoteData: {
                                  ...pagedata.remoteData,
                                  question: e.target.value,
                                },
                              });
                            }}
                            className="question-textfield"
                            value={pagedata.remoteData.question}
                          />
                        </Grid>
                        <Grid item xs="12" p={'10px 0px'}>
                          {pagedata?.remoteData.questionOptions?.length > 0 &&
                            pagedata?.remoteData.questionOptions?.map(
                              (item, index) => {
                                return (
                                  <Grid
                                    container
                                    item
                                    alignItems="center"
                                    spacing={1}
                                    key={index}
                                    p={1}
                                  >
                                    <Grid item xs="2" sm="1" md="2" lg="1">
                                      <Checkbox
                                        checked={item.selected}
                                        onChange={(event) =>
                                          checkboxClickHandler(index, event)
                                        }
                                      />
                                    </Grid>

                                    <Grid item xs="10" sm="11" md="10" lg="11">
                                      <CustomTextField
                                        className={
                                          item.selected
                                            ? 'selected-checkbox'
                                            : ''
                                        }
                                        fullWidth
                                        type="text"
                                        value={item.option}
                                        placeholder={item?.label}
                                        onChange={(event) =>
                                          textFiledClickHandler(index, event)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )}
                        </Grid>

                        <Grid item xs={12} style={{}}>
                          <CustomDateTimePicker
                            inputLabel="Expiry Time"
                            pagedata={pagedata}
                            setPagedata={setPagedata}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 10 }}>
                          <CustomTextField
                            inputLabel="Minimum Bet amount"
                            fullWidth
                            type="text"
                            placeholder="Bet Amount"
                            onChange={(e) => {
                              setPagedata({
                                ...pagedata,
                                remoteData: {
                                  ...pagedata.remoteData,
                                  betAmount: e.target.value,
                                },
                              });
                            }}
                            value={pagedata.remoteData.betAmount}
                            style={{ marginBottom: 10 }}
                          />
                        </Grid>
                      </>
                    )}
                    {pagedata.notificationType == stringConstant.Update && (
                      <>
                        <Grid item xs="12">
                          <CustomTextField
                            inputLabel="App Version"
                            fullWidth
                            type="text"
                            placeholder="App Version"
                            onChange={(e) => {
                              setPagedata({
                                ...pagedata,
                                body: filterQuesArr.notificationType
                                  .filter(
                                    (o) => o.value == stringConstant.Update
                                  )[0]
                                  .body.replace('{appversion}', e.target.value),
                                remoteData: {
                                  ...pagedata.remoteData,
                                  appVersion: e.target.value,
                                },
                              });
                            }}
                            value={pagedata.remoteData.appVersion}
                            style={{ marginBottom: 10 }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {previewError && (
                <Grid item xs="12" style={{ marginTop: 50 }}>
                  <Typography style={{ color: 'red' }}>
                    Fill all required field
                  </Typography>
                </Grid>
              )}
              <Grid item xs="4" style={{ marginTop: 50 }}>
                <CustomButton
                  variant="contained"
                  btnText="Preview Question"
                  fullWidth
                  btnClass={{ backgroundColor: '#4545A5', color: '#fff' }}
                  onClick={showPreviewHandler}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid container item xs={12}>
                <Grid item xs="5">
                  <Grid item xs="12" style={{ marginBlock: 20 }}>
                    <Typography variant="span">
                      Notification Type{'  '}
                      <Typography variant="span" style={{ fontWeight: 'bold' }}>
                        {pagedata?.notificationType}
                      </Typography>
                    </Typography>
                  </Grid>{' '}
                  <Grid container item xs={12} style={{ marginTop: 20 }}>
                    <Typography>Push Notification Layout</Typography>
                    <Grid
                      container
                      item
                      xs="12"
                      style={{
                        borderWidth: 1,
                        borderColor: '#000',
                        borderStyle: 'solid',
                        padding: 16,
                        borderRadius: 10,
                        marginTop: 10,
                      }}
                    >
                      <Grid item xs="12">
                        <Typography>{pagedata?.title}</Typography>
                      </Grid>
                      <Grid item xs="12">
                        <Typography>{pagedata?.body}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {(pagedata.notificationType == stringConstant.Live_Message ||
                    pagedata.notificationType ==
                      stringConstant.Remind_Before_Expiry) && (
                    <Grid container item xs={12} style={{ marginTop: 20 }}>
                      <Typography>Notification card</Typography>
                      <Grid
                        container
                        item
                        xs="12"
                        style={{
                          borderWidth: 1,
                          borderColor: '#000',
                          borderStyle: 'solid',
                          padding: 16,
                          borderRadius: 10,
                          marginTop: 10,
                        }}
                      >
                        <Grid item xs="12">
                          <Typography>
                            {pagedata?.remoteData.question}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {(pagedata.notificationType == stringConstant.Live_Message ||
                  pagedata.notificationType ==
                    stringConstant.Remind_Before_Expiry) && (
                  <>
                    <Grid
                      item
                      xs={1}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs="6" style={{ paddingBlock: 20 }}>
                      <Grid item xs="12">
                        <Grid container item justifyContent={'center'}>
                          <Grid item xs="4">
                            <Typography variant="body2">
                              Bet Amount : {pagedata?.remoteData.betAmount}
                            </Typography>
                          </Grid>
                          <Grid item xs="8">
                            <Typography variant="body2">
                              Expiry Time :{' '}
                              {dayjs(pagedata.remoteData.expiryTime).format(
                                'DD-MM-YYYYTHH:mm:ss'
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs="12">
                            <Box className="question-wrap">
                              <Typography
                                p={1}
                                color={'#6b6b6b'}
                                fontWeight={'bolder'}
                              >
                                {pagedata?.remoteData.question}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs="12">
                            {pagedata?.remoteData.questionOptions?.length > 0 &&
                              pagedata?.remoteData.questionOptions?.map(
                                (item, index) => {
                                  return (
                                    <Grid
                                      container
                                      item
                                      alignItems="center"
                                      spacing={1}
                                      key={index}
                                      p={1}
                                      className="preview-checkbox-field"
                                    >
                                      {/* <Grid item xs="2" sm="1" md="2" lg="1.5">
                          <Checkbox
                            disabled
                            checked={item.selected}
                            onChange={(event) =>
                              checkboxClickHandler(index, event)
                            }
                          />
                        </Grid> */}
                                      <Grid item xs="12">
                                        <CustomTextField
                                          className={
                                            item.selected == true &&
                                            'selected-checkbox'
                                          }
                                          fullWidth
                                          type="text"
                                          disabled
                                          value={item.option}
                                          placeholder={item?.label}
                                          onChange={(event) =>
                                            textFiledClickHandler(index, event)
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                }
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item xs="12">
                  <Divider variant="fullWidth" style={{ marginTop: 20 }} />
                </Grid>

                <Grid
                  item
                  xs="12"
                  alignSelf="center"
                  style={{ paddingTop: 10 }}
                  justifyContent={'space-around'}
                  alignItems="center"
                  display={'flex'}
                >
                  <Grid
                    container
                    item
                    justifyContent={'space-around'}
                    p={'10px 0px'}
                    className="delete-view-btn"
                    xs="6"
                  >
                    <Grid item xs="5">
                      <CustomButton
                        variant="contained"
                        btnText="Back"
                        fullWidth
                        onClick={() => setShowPreview(false)}
                      />
                    </Grid>
                    <Grid item xs="5">
                      {Object.keys(currentQuestionData).length > 0 ? (
                        <CustomButton
                          variant="contained"
                          btnText="Update Notification"
                          fullWidth
                          onClick={updateNotificationHandler}
                        />
                      ) : (
                        <CustomButton
                          variant="contained"
                          btnText="Create Notification"
                          fullWidth
                          onClick={createNotificationHandler}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      {Object.keys(snakeBarProps).length > 0 && (
        <CustomSnackbar
          {...snakeBarProps}
          setSnakeBarProps={setSnakeBarProps}
        />
      )}
    </>
    // </AuthLayout>
  );
};
