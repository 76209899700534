import { resetState } from '../redux/commonSlice';

export const clearAsyncStorageData = async () => {
  return window.localStorage.clear();
};
export const getAsyncStorageData = async (mappedKey) => {
  // return  AsyncStorage.getItem(key);
  const ls = JSON.parse(window.localStorage.getItem('persist:root'));
  // console.log('ls', ls);
  return ls[mappedKey];
};
export const setAsyncStorageData = async (key, data) => {
  window.localStorage.setItem(key, JSON.stringify(data));
};

export const routeTo = (pageUrl, navigation, params = {}) => {
  navigation.navigate(pageUrl, params);
};
export const logout = (navigation, dispatch) => {
  dispatch(resetState());
  clearAsyncStorageData();
  navigation('/admincontrol');
};
export const afterValidate = (callBack, setSnakeBarProps) => {
  var errorMszDom = [];
  setTimeout(() => {
    errorMszDom = document.getElementsByClassName('errorDom');
    if (errorMszDom.length == 0) {
      callBack();
    } else {
      setSnakeBarProps &&
        setSnakeBarProps({
          snackbarFlag: true,
          msz: 'Please fill all the required field',
          type: 'error',
        });
    }
  });
};

export const setInput = (value, type, pageData, setPageData) => {
  setPageData({ ...pageData, [type]: value });
};

export const updateSelectBoxArr = (arr) => {
  return arr.map((o) => ({ value: o }));
};
