import { apiConstant } from '../services/apiConstants';
import useRequestHook from '../services/request';

const QuestionApiHook = () => {
  const { Request } = useRequestHook();

  const uploadImage = (data) => {
    console.log('data', data);
    return Request.post(apiConstant.s3Upload, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };
  const createQuestion = (data) => {
    console.log('data', data);
    return Request.post(apiConstant.question, data);
  };
  const getAllQuestion = (data) => {
    return Request.get(apiConstant.questionAll);
  };
  const updateQuestionById = ({ questionId, data }) => {
    console.log('data', data);
    return Request.put(apiConstant.question + '/' + questionId, data);
  };
  const deleteQuestionById = (questionId) => {
    return Request.delete(apiConstant.question + '/' + questionId);
  };

  return {
    createQuestion,
    getAllQuestion,
    deleteQuestionById,
    updateQuestionById,
    uploadImage,
  };
};

export default QuestionApiHook;
