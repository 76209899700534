import { Box, Button, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../components/Button';
import { useDispatch } from 'react-redux';
import { logout } from '../../../utils/CommonLogic';
import Sidebar from './Sidebar';
import useThemeHook from '../../../hooks/ThemeHook';

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { xs_layout } = useThemeHook();

  return (
    <Box>
      <Grid container className="header-container" p={2}>
        <Grid item xs={6} md={2}>
          <Box display={'flex'} alignItems={'center'}>
            {/* <MenuIcon
            className="menu-icon"
            sx={{ color: 'red', fontSize: '40px' }}
            /> */}
            <Box
              xs={12}
              lg={12}
              style={{
                display: 'inline-block',
                cursor: 'pointer',
                width: 'auto',
              }}
              mr={3}
              onClick={() => navigate('/')}
            >
              <Typography color={'#0000008c'} fontWeight={'bolder !important'}>
                Insta Jawab Admin
              </Typography>
            </Box>
          </Box>
        </Grid>

        {!xs_layout && (
          <Grid item xs={6} md={8}>
            <Sidebar />{' '}
          </Grid>
        )}

        <Grid item xs={6} md={2} style={{ textAlign: 'right' }}>
          <CustomButton
            btnText="Logout"
            variant="clear"
            onClick={() => logout(navigate, dispatch)}
          />
        </Grid>
        {xs_layout && (
          <Grid item xs={12}>
            <Sidebar />{' '}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
