import { apiConstant } from '../services/apiConstants';
import useRequestHook from '../services/request';

const useConfigrutionAPIHook = () => {
  const { Request } = useRequestHook();

  const getConfig = (data) => {
    return Request.get(apiConstant.config, data);
  };

  const updateConfig = (data) => {
    return Request.put(apiConstant.config, data);
  };
  return {
    getConfig,
    updateConfig,
  };
};

export default useConfigrutionAPIHook;
