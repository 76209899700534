import { apiConstant } from '../services/apiConstants';
import useRequestHook from '../services/request';

const useUnAuthCrudApiHook = () => {
  const { Request } = useRequestHook();

  const signUp = (data) => {
    return Request.post(apiConstant.userRegister, data);
  };
  const signUpWithPin = (data) => {
    return Request.post(apiConstant.userRegisterPin, data);
  };
  const signIn = (data) => {
    return Request.post(apiConstant.userLogin, data);
  };
  const signInWithPin = (data) => {
    return Request.post(apiConstant.userLoginPin, data);
  };
  return {
    signUp,
    signUpWithPin,
    signIn,
    signInWithPin,
  };
};

export default useUnAuthCrudApiHook;
