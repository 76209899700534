import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import AuthLayout from '../AuthLayout';
import { useMutation, useQuery } from 'react-query';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Typography,
} from '@mui/material';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import { CreateNotification } from '../notification/Create';
import NotificationApiHook from '../../../hooks/NotificationApiHook';
import moment from 'moment';
import { CustomButton } from '../../../components/Button';
import { stringConstant } from '../../../constant';
import useThemeHook from '../../../hooks/ThemeHook';

const Notification = () => {
  const {
    getNotification,
    sendNotification,
    updateNotificationById,
    sendNotificationResult,
    deleteNotificatioHisById,
  } = NotificationApiHook();

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [action, setAction] = useState('');
  const [deleteConfirmFlag, setDeleteConfirmFlag] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const [drawerData, setDrawerData] = useState({});
  const { xs_layout } = useThemeHook();

  const notificationStatusArr = [
    stringConstant.Live_Message,
    stringConstant.Remind_Before_Expiry,
    stringConstant.Result,
  ];
  const format = 'YYYY-MM-DDTHH:mm:ss';

  const {
    data: userArr,
    isLoading: userDataLoader,
    refetch: questionListRefech,
  } = useQuery(['getNotification'], () => getNotification(), {
    enabled: false,
  });

  useEffect(() => {
    questionListRefech();
  }, []);

  const { mutate: sendNotificationMutate, isLoading: sendNotificationLoader } =
    useMutation(sendNotification, {
      onSuccess: (data, variable) => onSuccessSendNotification(data, variable),
      onError: (data) => onErrorResgister(data),
    });
  const {
    mutate: deleteNotificationMutate,
    isLoading: deleteNotificationLoader,
  } = useMutation(deleteNotificatioHisById, {
    onSuccess: (data, variable) => onSuccessIUpdateNotification(data, variable),
    onError: (data) => onErrorResgister(data),
  });
  const {
    mutate: sendNotificationResultMutate,
    isLoading: sendNotificationResultLoader,
  } = useMutation(sendNotificationResult, {
    onSuccess: (data, variable) =>
      onSuccessSendResultNotification(data, variable),
    onError: (data) => onErrorResgister(data),
  });

  const {
    mutate: updateNotificationByIdMutate,
    isLoading: updateQuestionByIdLoader,
  } = useMutation(updateNotificationById, {
    onSuccess: (data) => onSuccessIUpdateNotification(data),
    onError: (data) => onErrorResgister(data),
  });

  const onSuccessSendResultNotification = (data, variable) => {
    sendNotificationMutate(variable.rowData);
    updateNotificationByIdMutate({
      notificationId: variable.notificationId,
      data: {
        updatedAt: moment().format(format),
        track: 'Delete',
        remindCount: 0,
      },
    });
  };

  const onSuccessSendNotification = (data, variable) => {
    updateNotificationByIdMutate({
      notificationId: data.data.data.data.notificationId,
      data: {
        activeStatus: 'Active',
        updatedAt: moment().format(format),
        track: notificationStatusArr.includes(variable?.notificationType)
          ? checkType(variable?.remoteData?.expiryTime) == 'New'
            ? variable.track === 'Just Created'
              ? 'Sent'
              : variable.track === 'Sent' || variable.track === 'Reminder'
              ? 'Reminder'
              : ''
            : variable.track === 'Sent' || variable.track === 'Reminder'
            ? 'Result'
            : 'Delete'
          : 'Just Created',
        //   : 'Ready to Delete',
        remindCount:
          variable.track === 'Sent' || variable.track === 'Reminder'
            ? variable.remindCount + 1
            : 0,
      },
    });
  };

  const onSuccessIUpdateNotification = (data) => {
    if (data.data.status) {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'success',
      });
      questionListRefech();
    } else {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'error',
      });
    }
  };

  const onErrorResgister = (data) => {
    // console.log('data', data);
    // setToggleDrawer(false);
  };
  const checkType = (expiryTime) => {
    var expiryDate = moment(expiryTime).format(format);
    var endDate = moment(expiryDate);
    var now = moment().format(format);
    const seconds = endDate.diff(now, 'seconds');
    return seconds > 0 ? 'New' : 'Expired';
  };

  const userData =
    userArr?.data?.data.map((o) => ({
      ...o,
      track:
        checkType(o?.remoteData?.expiryTime) == 'Expired' &&
        (o.track == stringConstant.track.Sent ||
          o.track == stringConstant.track.Reminder)
          ? 'Result'
          : o.track,
    })) ?? [];
  const handleCancel = () => {
    setDeleteConfirmFlag(false);
  };

  const handleOk = () => {
    setDeleteConfirmFlag(false);
    deleteNotificationMutate(currentId);
  };

  const checkNotificationstatus = (expiryTime, notificationType) => {
    return notificationStatusArr.includes(notificationType)
      ? checkType(expiryTime)
      : '-----';
  };

  return (
    <AuthLayout>
      {(sendNotificationLoader ||
        deleteNotificationLoader ||
        userDataLoader ||
        sendNotificationResultLoader ||
        updateQuestionByIdLoader) && (
        <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      <Box sx={{ width: xs_layout ? '100%' : '90%', margin: '20px auto' }}>
        <MaterialTable
          columns={[
            {
              title: 'Id',
              field: 'notificationId',
              cellStyle: { width: '4%' },
            },
            {
              title: 'Track',
              field: 'track',
              cellStyle: { width: '4%' },

              render: (rowData) =>
                notificationStatusArr.includes(rowData.notificationType)
                  ? rowData.track == 'Reminder'
                    ? `Reminder_${rowData.remindCount}`
                    : rowData.track
                  : '-----',
            },
            {
              title: 'Type',
              field: 'notificationType',
              cellStyle: { width: '4%' },
            },

            {
              title: 'Status',
              field: 'notificationStatus',
              cellStyle: { width: '4%', textAlign: 'center' },
              render: (rowData) =>
                checkNotificationstatus(
                  rowData?.remoteData?.expiryTime,
                  rowData.notificationType
                ),
            },
            {
              title: 'TimeLeft',
              field: 'expiryTime',
              cellStyle: { width: '8%', textAlign: 'center' },
              render: (rowData) => {
                // console.log('rowData', rowData);
                if (notificationStatusArr.includes(rowData.notificationType)) {
                  var expiryDate = moment(
                    rowData?.remoteData?.expiryTime
                  ).format(format);
                  var endDate = moment(expiryDate);
                  var now = moment().format(format);
                  const days = endDate.diff(now, 'days');
                  const hours = endDate.diff(now, 'hours');
                  const minutes = endDate.diff(now, 'minutes');
                  const seconds = endDate.diff(now, 'seconds');
                  if (seconds > 0) {
                    var timer =
                      days != 0
                        ? days + ' Day'
                        : hours != 0
                        ? hours + ' Hour'
                        : minutes != 0
                        ? minutes + ' Min'
                        : seconds != 0
                        ? seconds + ' Sec'
                        : '';

                    return timer;
                  } else {
                    return checkNotificationstatus(
                      rowData?.remoteData?.expiryTime,
                      rowData.notificationType
                    ) == 'New'
                      ? 'Time Over'
                      : '-----';
                  }
                } else {
                  return '----';
                }
              },
            },

            {
              title: 'Created_At',
              field: 'createAt',
              cellStyle: { width: '8%' },
            },
            {
              title: 'Updated_At',
              field: 'updatedAt',
              cellStyle: { width: '8%' },
            },
            {
              title: 'Question',
              field: 'questionLabel',
              // cellStyle: { width: '80%' },
              render: (rowData) =>
                notificationStatusArr.includes(rowData.notificationType)
                  ? rowData.remoteData.questionLabel
                  : '-----',
            },
            {
              title: 'Active',
              field: 'activeStatus',
              cellStyle: { width: '4%' },
            },
          ]}
          data={userData}
          title="Notification"
          onRowClick={(event, rowData) => {
            setAction('edit');
            setDrawerData(rowData);
            setToggleDrawer(true);
          }}
          actions={[
            (rowData) => {
              return (
                rowData.track == 'Delete' &&
                rowData.activeStatus == 'Active' && {
                  icon: 'delete',
                  tooltip: 'Delete User',
                  onClick: (event, rowData) => {
                    setDeleteConfirmFlag(true);
                    setCurrentId(rowData.notificationId);
                  },
                }
              );
            },
            (rowData) => {
              return (
                rowData.track != 'Delete' && {
                  icon: 'send',
                  tooltip: 'Send Notification',
                  onClick: () => {
                    rowData.notificationType == stringConstant.Live_Message ||
                    rowData.notificationType == stringConstant.Result ||
                    rowData.notificationType ==
                      stringConstant.Remind_Before_Expiry
                      ? rowData.track === 'Result'
                        ? rowData.notificationType !== stringConstant.Result
                          ? setSnakeBarProps({
                              snackbarFlag: true,
                              msz: 'Please update notifciation type to Result',
                              type: 'error',
                            })
                          : sendNotificationResultMutate({
                              rowData,
                              notificationId: rowData.notificationId,
                              data: {
                                answer:
                                  rowData.remoteData.questionOptions.filter(
                                    (o) => o.selected
                                  )[0].option,
                              },
                            })
                        : checkNotificationstatus(
                            rowData?.remoteData?.expiryTime,
                            rowData.notificationType
                          ) == 'New'
                        ? rowData.track == 'Sent' &&
                          rowData.notificationType !==
                            stringConstant.Remind_Before_Expiry
                          ? setSnakeBarProps({
                              snackbarFlag: true,
                              msz: 'Please update notifciation type to Remind before Expiry',
                              type: 'error',
                            })
                          : sendNotificationMutate(rowData)
                        : setSnakeBarProps({
                            snackbarFlag: true,
                            msz: 'Notification Expired',
                            type: 'error',
                          })
                      : sendNotificationMutate(rowData);

                    // setDeleteConfirmFlag(true);
                    // setCurrentId(rowData.questionId);
                    // sendNotificationResult
                  },
                }
              );
            },
            {
              icon: 'add',
              tooltip: 'Add Notification',
              name: '13',
              isFreeAction: true,
              onClick: () => {
                setAction('add');
                setDrawerData({});
                setToggleDrawer(true);
              },
            },
            {
              icon: 'refresh',
              tooltip: 'Refresh',
              name: '3',
              isFreeAction: true,
              onClick: () => {
                questionListRefech();
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            grouping: true,
            pageSize: 10,
            search: true,
          }}
        />
      </Box>

      {toggleDrawer && (
        <Drawer
          open={true}
          anchor="right"
          onClose={() => setToggleDrawer(false)}
        >
          <Box sx={{ p: 2, width: '80vw' }}>
            <CreateNotification
              preview={false}
              setToggleDrawer={setToggleDrawer}
              questionListRefech={questionListRefech}
              questionId={currentId}
              currentQuestionData={drawerData}
            />
          </Box>
        </Drawer>
      )}
      {deleteConfirmFlag && (
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth="xs"
          open={true}
        >
          <DialogTitle>Do you want to delete this Question?</DialogTitle>
          <DialogContent dividers>
            <Typography fontWeight="700">NotificationId {currentId}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Ok</Button>
          </DialogActions>
        </Dialog>
      )}
      {Object.keys(snakeBarProps).length > 0 && (
        <CustomSnackbar
          {...snakeBarProps}
          setSnakeBarProps={setSnakeBarProps}
        />
      )}
    </AuthLayout>
  );
};

export default Notification;
