import React from 'react';
import HomeLayout from './HomeLayout';
import AppInfoLayout from './AppInfoLayout';
import { Grid } from '@material-ui/core';
import { useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

function TnC() {
  const theme = useTheme();
  const mdLayout = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <HomeLayout>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: 'calc(100vh - 44px)', padding: 16 }}
      >
        <Grid container item xs={12} md={10} justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <AppInfoLayout />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            style={{ marginBottom: mdLayout ? 0 : 100 }}
          >
            <div class="container" style={{ marginBottom: 100 }}>
              <h1>Terms and Conditions</h1>

              <h2>1. Free Points and Registration</h2>
              <p>
                Upon registration, users will receive{' '}
                <strong>100 points</strong> to start participating in the
                challenges. These points act as a free subscription. The initial
                points awarded may vary between users.
              </p>

              <h2>2. Question Participation</h2>
              <p>
                New questions appear every time on play button click. Users
                needs to answer every question till plan limits end.
              </p>
              <p>The duration of each question may change at any time.</p>

              <h2>3. Choose Points</h2>
              <p>
                A minimum of <strong>50 points</strong> is required to answer a
                question. This minimum may change without prior notice.
              </p>

              <p>
                Correct answers will increase the user's points, while incorrect
                answers will reduce them.
              </p>

              <h2>4. Redeeming Points</h2>
              <p>
                Users can convert their accumulated points into money by
                submitting a redeem request.
              </p>
              <p>
                A valid UPI ID must be provided for the transfer, which will be
                subject to a <strong>12% transfer fee</strong>.
              </p>

              <h2>5. Depletion of Points</h2>
              <p>
                If a user's points balance reaches <strong>0</strong>, they must
                renew their subscription by paying an amount of their choice,
                which will provide them with an equivalent number of points.
              </p>
              <p>
                Renewing the subscription is necessary to continue participating
                in the challenges.
              </p>

              <h2>6. Bonus Question Participation</h2>
              <p>
                Users may receive notifications to participate in{' '}
                <strong>Bonus Questions</strong>. Participation in bonus
                questions requires a minimum number of points.
              </p>
              <p>
                Points will increase or decrease based on the correctness of the
                user's answers.
              </p>
              <p>
                Results for bonus questions will not be immediate and will be
                communicated through notifications.
              </p>

              <h2>7. Subscription Validity</h2>
              <p>
                The subscription will remain valid for a lifetime unless the
                user's points balance reaches <strong>0</strong>.
              </p>

              <h2>8. Account Suspension and Cancellation</h2>
              <p>
                InstaJawab reserves the right to suspend or cancel any user
                account at any time, with or without notice.
              </p>
              <p>
                Users may contact <strong>instajawab@gmail.com</strong> for any
                issues or concerns related to the app. The support team will
                make every effort to resolve user queries promptly.
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </HomeLayout>
  );
}

export default TnC;
