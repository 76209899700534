import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserApiHook from '../hooks/UserApiHook';
import { useMutation } from 'react-query';
import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';

function VerifyEmail(props) {
  const location = useLocation();
  const { verifyUserEmail } = UserApiHook();

  const [verifyEmailFlag, setVerifyEmailFlag] = useState(false);
  const [verifyEmailIssueFlag, setVerifyEmailIssueFlag] = useState(false);

  const { mutate: updateUserMutate, isLoading: updateUserLoader } = useMutation(
    verifyUserEmail,
    {
      onSuccess: (data) => onSuccessRegister(data),
      onError: (data) => onErrorResgister(data),
    }
  );

  const onSuccessRegister = (data) => {
    if (data.data.status) {
      setVerifyEmailFlag(true);
    }
  };

  const onErrorResgister = (data) => {
    console.log('data', data);
    // setToggleDrawer(false);
    setVerifyEmailIssueFlag(true);
  };

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  useEffect(() => {
    updateUserMutate({ token: token });

    setTimeout(() => {
      window.close();
    }, 5000);
  }, []);

  return (
    <>
      {updateUserLoader && (
        <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      {verifyEmailFlag && (
        <Box sx={{ alignContent: 'center', height: '100vh' }}>
          <Typography variant="h1" style={{ textAlign: 'center' }}>
            Your Email is verified successfully
          </Typography>
          <Typography
            variant="body"
            style={{ textAlign: 'center', display: 'block', marginTop: 10 }}
          >
            Window will be closed after 5 sec
          </Typography>
        </Box>
      )}
      {verifyEmailIssueFlag && (
        <Box sx={{ alignContent: 'center', height: '100vh' }}>
          <Typography variant="h3" style={{ textAlign: 'center' }}>
            There was an issue verifying your email. Please try again.
          </Typography>
          <Typography
            variant="body"
            style={{ textAlign: 'center', display: 'block', marginTop: 10 }}
          >
            Window will closed after 5 sec
          </Typography>
        </Box>
      )}
    </>
  );
}

export default VerifyEmail;
