import { useMediaQuery, useTheme } from '@material-ui/core';

function useThemeHook() {
  const theme = useTheme();
  const xs_layout = useMediaQuery(theme.breakpoints.down('xs'));

  return {
    xs_layout,
  };
}

export default useThemeHook;
