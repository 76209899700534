import React from 'react';
import HomeLayout from './HomeLayout';
import AppInfoLayout from './AppInfoLayout';
import { Grid } from '@material-ui/core';
import { useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

function AboutUs() {
  const theme = useTheme();
  const mdLayout = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <HomeLayout>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: 'calc(100vh - 44px)', padding: 16 }}
      >
        <Grid container item xs={12} md={10} justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <AppInfoLayout />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            style={{ marginBottom: mdLayout ? 0 : 100 }}
          >
            <div class="about-us container" style={{ marginBottom: 100 }}>
              <h1>InstaJawab: Quick Reward Challenge App</h1>
              <h4>Join the Quick Reward Challenge and Start Winning Today!</h4>
              <div class="overview">
                <p>
                  InstaJawab is a dynamic and engaging platform where users can
                  earn reward by answering questions. Designed for those who
                  love a challenge and want to make quick reward on the go, the
                  app provides a steady stream of questions. Users can set the
                  points for their answers and win reward based on their
                  knowledge and speed.
                </p>
              </div>

              <div class="how-it-works">
                <h2>How It Works</h2>
                <ol>
                  <li>
                    <strong>Answer Questions to Earn Reward:</strong>
                    <p>
                      Every time, a new question appears on the screen. Users
                      can choose to answer based on their preferences and
                      knowledge.
                    </p>
                  </li>
                  <li>
                    <strong>Choose points For an question:</strong>
                    <p>
                      Place an points on the answer you provide. Correct answers
                      win reward proportional to the points. The more confident
                      you are, the higher the points you can add to maximize
                      your earnings.
                    </p>
                  </li>
                  <li>
                    <strong>Selective Participation:</strong>
                    <p>
                      It is not mandatory to answer every question. Users can
                      selectively play and answer their favorite or most
                      confident questions.
                    </p>
                  </li>
                  <li>
                    <strong>Quick reward Out:</strong>
                    <p>
                      Convert your rewards into gift cards or bank transfer in
                      just a few easy steps. Enjoy the flexibility and
                      convenience of accessing your earnings quickly.
                    </p>
                  </li>
                </ol>
              </div>

              <div class="features">
                <h2>Features</h2>
                <ul>
                  <li>
                    <strong>User-Friendly Interface:</strong> Navigate easily
                    through questions and choosing points.
                  </li>
                  <li>
                    <strong>Real-Time Updates:</strong> Experience a seamless
                    and fast-paced question flow, keeping you engaged.
                  </li>
                  <li>
                    <strong>Secure Transactions:</strong> Ensure your winnings
                    are transferred securely to your bank account.
                  </li>
                  <li>
                    <strong>Wide Range of Topics:</strong> Answer questions from
                    various categories, increasing your chances of winning based
                    on your knowledge strengths.
                  </li>
                </ul>
              </div>

              <div class="why-choose">
                <h2>Why Choose InstaJawab?</h2>
                <ul>
                  <li>
                    <strong>Earn Reward Fast:</strong> Turn your knowledge into
                    Reward with quick, real-time question challenges.
                  </li>
                  <li>
                    <strong>Flexible Play:</strong> Play at your own pace,
                    answer questions you’re confident in, and skip the rest.
                  </li>
                  <li>
                    <strong>Convenient Reward Out:</strong> Enjoy the
                    convenience of transferring your earnings to your bank
                    account effortlessly.
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </HomeLayout>
  );
}

export default AboutUs;
