import { Button, Typography } from '@material-ui/core';
import { Box, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import logo from '../assets/logo.png';
import { useTheme } from '@mui/styles';
import google from '../assets/playstoreLogo.png';

function AppInfoLayout(props) {
  const { parent } = props;
  const theme = useTheme();
  const mdLayout = useMediaQuery(theme.breakpoints.up('md'));

  const topLayout = mdLayout
    ? {
        textAlign: 'center',
        position: 'fixed',
        top: '50%',
        transform: 'translate(0, -50%)',
      }
    : {
        textAlign: 'center',
        marginBottom: 30,
      };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <Grid item xs={12} style={parent == 'home' ? {} : topLayout}>
        <img src={logo} alt="Uploaded" style={{ width: '30%' }} />
        <Typography
          style={{
            fontSize: mdLayout ? 60 : 52,
            letterSpacing: '7px !important',
            lineHeight: 1,
            textAlign: 'center',
            color: '#de4278',
          }}
        >
          Insta Jawab
        </Typography>

        <Typography
          style={{
            padding: '30px 0px !important',
            fontSize: 18,
            textAlign: 'center',
            color: '#095068',
            marginBottom: mdLayout ? 20 : 20,
          }}
        >
          Quick Reward Challenge
        </Typography>

        <a
          href="https://play.google.com/store/apps/details?id=com.instajawab"
          target="_blank"
        >
          <img src={google} width="200px" alt="" />
        </a>
      </Grid>
    </Grid>
  );
}

export default AppInfoLayout;
