import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import logo from '../assets/logo.png';

function NotFound() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh', textAlign: 'center' }}
    >
      <Grid item>
        <img
          src={logo}
          alt="Uploaded"
          style={{ width: '30%', marginBottom: 30 }}
        />

        <Typography>You have no access to this page,</Typography>
        <Typography>Page is accessible only to admin.</Typography>
      </Grid>
    </Grid>
  );
}

export default NotFound;
