export const responsiveStype = {
  default: {
    hide_mobile: {
      display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
    },
    hide_desktop: {
      display: { xs: 'block', sm: 'block', md: 'nonr', lg: 'none' },
    },
  },
  OnboardingLayout: {
    MainWarpper: { height: { lg: '100vh', xs: '80vh' } },
  },
  UnAuthLayout: {
    MaincWarpper: { height: { lg: '100vh', xs: '85vh' } },
  },
  CreatePassword: {
    Typo: { textAlign: { xs: 'center', lg: 'left' } },
  },
  ResetPassword: {
    Typo: { textAlign: { xs: 'center', lg: 'left' } },
  },
  ForgotPassword: {
    Typo: { textAlign: { xs: 'center', lg: 'left' } },
  },
  Signup: {
    Typo: { textAlign: { xs: 'center', lg: 'left' } },
  },
  Login: {
    Typo: { textAlign: { xs: 'center', lg: 'left' } },
  },
  Mobilever: {
    Typo: { textAlign: { xs: 'center', lg: 'left' } },
  },
  ForgotPassword: {
    Typo: { textAlign: { xs: 'center', lg: 'left' }, fontFamily: 'Urbantist' },
  },
  Signup: {
    Typo: { textAlign: { xs: 'center', lg: 'left' }, fontFamily: 'Urbantist' },
  },
  Login: {
    Typo: { textAlign: { xs: 'center', lg: 'left' }, fontFamily: 'Urbantist' },
    Button: {
      textAlign: { xs: 'center', md: 'right' },
    },
  },
  Mobilever: {
    Typo: { textAlign: { xs: 'center', lg: 'left' }, fontFamily: 'Urbantist' },
  },
  Personal: {
    Typo: { textAlign: { xs: 'center', lg: 'left' } },
  },
  School: {
    Typo: { textAlign: { xs: 'center', lg: 'left' } },
  },
};
