import React, { useState } from 'react';
import { Box, Chip, Grid, TextField } from '@material-ui/core';
import { Typography } from '@mui/material';
import { CustomButton } from '../../../components/Button';
import { chipMappedNameArr } from '../../../models/SideMenu';

function ChipBox(props) {
  const { dataArr, chipType, setConfigData } = props;

  const [inputVal, setinputVal] = useState('');
  const [updateFlag, setUpdateFlag] = useState(false);
  const [currentChipId, setCurrentChipId] = useState(null);

  const handleClick = (chipText, index) => {
    setCurrentChipId(index);
    setUpdateFlag(true);
    setinputVal(chipText);
  };

  const handleDelete = (type, index) => {
    var localArray = dataArr[type];
    localArray.splice(index, 1);
    setConfigData({ ...dataArr, [type]: localArray });
  };

  const addnewChip = (type) => {
    var localArray = dataArr[type];
    localArray.push(inputVal);
    setConfigData({ ...dataArr, [type]: localArray });
    setinputVal('');
  };

  const updateChip = (type) => {
    var localArray = dataArr[type];
    localArray[currentChipId] = inputVal;
    setConfigData({ ...dataArr, [type]: localArray });
    setUpdateFlag(false);
  };

  const reset = () => {
    setUpdateFlag(false);
  };

  const getChipLabel = (label) => {
    return chipMappedNameArr.filter((o) => o.mappedKey == label)[0].label;
  };
  return (
    <Grid container item xs={12} md={12} style={{ marginBottom: 20 }}>
      <Grid item xs={12} md={2}>
        <Box>
          <Typography variant="body">{getChipLabel(chipType)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={10}>
        <Box
          style={{
            backgroundColor: '#fff',
            padding: 16,
            borderRadius: 10,
          }}
        >
          {Object.keys(dataArr).length > 0 &&
            dataArr[chipType].map((o, i) => {
              return (
                <Box
                  key={o}
                  style={{
                    display: 'inline-block',
                    paddingBlock: 5,
                    paddingInline: 5,
                    borderRadius: 10,
                    marginBottom: 5,
                  }}
                >
                  <Chip
                    label={o}
                    onClick={() => handleClick(o, i)}
                    onDelete={() => handleDelete(chipType, i)}
                  />
                </Box>
              );
            })}
          <Box>
            <TextField
              id="outlined-required"
              label={getChipLabel(chipType)}
              style={{ width: '100%' }}
              value={inputVal}
              // onChange={(e) => console.log('e', e)}
              onChange={(e) => setinputVal(e.target.value)}
            />
          </Box>
          <Box style={{ textAlign: 'right' }}>
            {updateFlag ? (
              <>
                <CustomButton
                  variant="contained"
                  btnText="Update"
                  btnClass={{
                    backgroundColor: '#4545A5',
                    color: '#fff',
                    width: 100,
                    marginTop: 16,
                    marginRight: 10,
                  }}
                  onClick={() => updateChip(chipType)}
                />
                <CustomButton
                  variant="contained"
                  btnText="Reset"
                  btnClass={{
                    backgroundColor: '#4545A5',
                    color: '#fff',
                    width: 100,
                    marginTop: 16,
                  }}
                  onClick={() => reset()}
                />
              </>
            ) : (
              <CustomButton
                variant="contained"
                btnText="Add new"
                btnClass={{
                  backgroundColor: '#4545A5',
                  color: '#fff',
                  width: 100,
                  marginTop: 16,
                }}
                onClick={() => addnewChip(chipType)}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ChipBox;
