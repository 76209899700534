import { Grid, Typography } from '@mui/material';
import React from 'react';

import igGif from '../assets/ij.gif';
import HomeLayout from './HomeLayout';
import AppInfoLayout from './AppInfoLayout';
import { useTheme } from '@mui/styles';
import { Box, Button, useMediaQuery } from '@material-ui/core';
import google from '../assets/playstoreLogo.png';

export default function Home() {
  const theme = useTheme();
  const mdLayout = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <HomeLayout>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: '#E7F1FA',
          paddingBottom: 100,
          paddingTop: 16,
        }}
        className="home"
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ backgroundColor: '#fff' }}
        >
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={4}>
            {/* <AppInfoLayout parent="home" /> */}
            <Typography variant="h2" textAlign="center">
              Welcome to InstaJawab
            </Typography>
            <Typography
              style={{
                padding: '30px 0px !important',
                fontSize: 30,
                textAlign: 'center',
                color: '#095068',
                marginBottom: 10,
              }}
            >
              Quick Reward Challenge
            </Typography>
            <Box
              textAlign={'center'}
              style={{ marginBottom: mdLayout ? 50 : 20 }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.instajawab"
                target="_blank"
              >
                <img src={google} width="200px" alt="" />
              </a>

              {/* <a href="/assets/instajawab.apk">
            <img src={ios} width="200px" alt="" />
          </a> */}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{ textAlign: 'center', padding: 16 }}
          >
            <img src={igGif} alt="Uploaded" style={{ width: 200 }} />
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
        <Grid container item xs={12} md={10} alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Box style={{ padding: 16 }}>
              <div class="content-section">
                <h2>Welcome to Insta Jawab!</h2>
                <p>
                  In Insta Jawab, users engage with a dynamic list of questions
                  that update every time. Each user starts with{' '}
                  <span class="important">100 points</span> and can choose a
                  question to answer, wagering their points.
                </p>

                <h3>How it Works:</h3>
                <ul>
                  <li>
                    <span class="important">Initial Points:</span> Users begin
                    with 100 points.
                  </li>
                  <li>
                    <span class="important">Answering Questions:</span> If a
                    user answers a question correctly, they win additional
                    points. If they answer incorrectly, they must add more
                    points to continue.
                  </li>
                  <li>
                    <span class="important">Adding Points:</span> Points can be
                    purchased via the Razorpay payment gateway, with 100 rupees
                    equaling 100 points. Once points are added, they cannot be
                    refunded or canceled.
                  </li>
                  <li>
                    <span class="important">Redeem Rewards:</span> Users can
                    request to convert their points into rupees. This process
                    takes 5-6 business days, and the converted amount is
                    directly transferred to the user's bank account.
                  </li>
                </ul>

                <p>
                  Insta Jawab combines quick thinking with instant rewards,
                  making every second count! Get ready to challenge yourself and
                  win big!
                </p>
              </div>

              <section class="content-section">
                <h2>Why Choose InstaJawab?</h2>

                <p>
                  <strong>Real Reward Points:</strong> Turn your knowledge into
                  real points and reward out instantly.
                </p>
                <p>
                  <strong>Secure and Easy:</strong> Our platform ensures safe
                  transactions and an easy-to-use interface.
                </p>
              </section>

              <section class="content-section" style={{ marginBottom: 100 }}>
                <h2>Download Now and Start Winning!</h2>
                <p>
                  Join thousands of players already enjoying the thrill of
                  InstaJawab. Download the app, test your knowledge, and start
                  winning reward today!
                </p>
                <Box style={{ textAlign: 'center' }}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.instajawab"
                    target="_blank"
                  >
                    <img src={google} width="200px" alt="" />
                  </a>
                </Box>
              </section>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </HomeLayout>
  );
}
