import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box, Typography } from '@material-ui/core';
import dayjs from 'dayjs';

export default function CustomDateTimePicker(props) {
  const { inputLabel, required, pagedata, setPagedata } = props;
  return (
    <>
      {inputLabel && (
        <Box mb={1}>
          <Typography variant="body1" component="span">
            <Box fontWeight="bold">
              {inputLabel}
              {required && <sub style={{ position: 'absolute' }}>* </sub>}
            </Box>
          </Typography>
        </Box>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          value={pagedata.remoteData.expiryTime}
          onChange={(newValue) => {
            setPagedata({
              ...pagedata,
              remoteData: {
                ...pagedata.remoteData,
                expiryTime: dayjs(newValue),
              },
            });
          }}
        />
      </LocalizationProvider>
    </>
  );
}
