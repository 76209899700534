import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AuthLayout from './AuthLayout';
import { useMutation, useQuery } from 'react-query';
import UserApiHook from '../../hooks/UserApiHook';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomTextField } from '../../components/TextField';
import TransactionApiHook from '../../hooks/TransactionApiHook';
import HistoryApiHook from '../../hooks/HistoryApiHook';
import { useNavigate } from 'react-router-dom';

function UserDetails() {
  const navigate = useNavigate();

  const { getUser, getUserById } = UserApiHook();
  const { getTransactionById } = TransactionApiHook();
  const { getHistoryByUserId } = HistoryApiHook();

  const [currentUserId, setCurrentUserId] = useState(null);
  const [userByIdData, setUserByIdData] = useState({});
  const [historyByIdData, setHistoryByIdData] = useState([]);
  const [transactionByIdData, setTransactionByIdData] = useState([]);

  const userArrCheck = [
    '_id',
    'notificationData',
    'withdrawalLimitArr',
    '__v',
    'password',
    'bioKey',
    'withdrawalLimit',
  ];

  const transArr = [
    'amount',
    'completedStatus',
    'date',
    'status',
    'transactionRefId',
  ];
  const historyArr = [
    'playhistoryId',
    'qId',
    'date',
    'amount',
    'betPoints',
    'status',
  ];

  const {
    data: userArr,
    isLoading: userDataLoader,
    refetch: userListRefetch,
  } = useQuery(['GetUser'], () => getUser(), {
    enabled: false,
  });

  useEffect(() => {
    userListRefetch();
  }, []);

  useEffect(() => {
    currentUserId != null && getUserByIdMutate(currentUserId);
    currentUserId != null && getTransactionByIdMutate(currentUserId);
    currentUserId != null && getHistoryByUserIdMutate(currentUserId);
  }, [currentUserId]);

  const onSuccessUser = (data) => {
    setUserByIdData(data?.data?.data?.data[0]);
  };
  const onErrorUser = (error) => {};

  const onSuccessHistory = (data) => {
    console.log(data?.data?.data?.data);
    setHistoryByIdData(data?.data?.data?.data);
  };
  const onSuccessTrans = (data) => {
    console.log(data?.data);
    setTransactionByIdData(data?.data?.data);
  };

  const {
    mutate: getHistoryByUserIdMutate,
    isLoading: getHistoryByUserIdLoader,
  } = useMutation(getHistoryByUserId, {
    onSuccess: onSuccessHistory,
    onError: onErrorUser,
  });
  const { mutate: getUserByIdMutate, isLoading: getUserByIdLoader } =
    useMutation(getUserById, {
      onSuccess: onSuccessUser,
      onError: onErrorUser,
    });

  const {
    mutate: getTransactionByIdMutate,
    isLoading: getTransactionByIdLoader,
  } = useMutation(getTransactionById, {
    onSuccess: onSuccessTrans,
    onError: onErrorUser,
  });

  const userData = userArr?.data?.data?.data ?? [];

  return (
    <AuthLayout>
      <Box style={{ maxHeight: 'calc(-89px + 100vh)', overflow: 'hidden' }}>
        <Grid justifyContent="center" style={{}} container>
          <Grid
            item
            xs={12}
            sm={2}
            lg={1}
            style={{
              backgroundColor: '#ddd',
            }}
          >
            {userDataLoader && (
              <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
                <Backdrop
                  style={{
                    color: '#fff',
                    zIndex: 2,
                  }}
                  open={true}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>
            )}

            <Typography
              variant="h6"
              style={{ padding: 8, textAlign: 'center' }}
            >
              Total User: {userData.length}
            </Typography>
            <Box p={2}>
              <CustomTextField
                fullWidth
                type="text"
                placeholder="UserId"
                variant="filled"
                value={currentUserId}
                onChange={(event) => {
                  setCurrentUserId(event.target.value);
                }}
              />
            </Box>
            <Box style={{ overflow: 'auto', height: 'calc(100vh - 225px)' }}>
              {userData.length > 0 &&
                userData.map((o) => {
                  return (
                    <Box
                      sx={{
                        marginY: 1,
                        backgroundColor:
                          o.userId == currentUserId ? '#fff' : '',
                      }}
                      onClick={() => setCurrentUserId(o.userId)}
                    >
                      <Typography style={{ padding: 8, textAlign: 'center' }}>
                        {o.userId}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={10}
            lg={11}
            style={{
              backgroundColor: '#fff',
              padding: 16,
              overflow: 'auto',
              height: 'calc(100vh - 80px)',
            }}
          >
            {(getUserByIdLoader ||
              getTransactionByIdLoader ||
              getHistoryByUserIdLoader) && (
              <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
                <Backdrop
                  style={{
                    color: '#fff',
                    zIndex: 2,
                  }}
                  open={true}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>
            )}
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Grid container style={{ justifyContent: 'space-between' }}>
                  <Grid item xs={12} sm={6}>
                    User details
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      onClick={() =>
                        navigate('/admincontrol/user', {
                          state: {
                            userIdFromParent: currentUserId,
                          },
                        })
                      }
                    >
                      <Typography style={{ textAlign: 'right' }}>
                        Go to User Table
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  {userByIdData &&
                    Object.keys(userByIdData).length > 0 &&
                    Object.keys(userByIdData).map((o) => {
                      return (
                        !userArrCheck.includes(o) && (
                          <Grid item xs={12} sm={4}>
                            <CustomTextField
                              fullWidth
                              type="text"
                              inputLabel={o}
                              placeholder={o}
                              variant="filled"
                              value={
                                typeof userByIdData[o] == 'object'
                                  ? JSON.stringify(userByIdData[o])
                                  : userByIdData[o]
                              }
                              // value={drawerData.points}
                              disabled={true}
                              // onChange={(event) => {
                              //   setDrawerData({
                              //     ...drawerData,
                              //     points: event.target.value,
                              //   });
                              // }}
                            />
                          </Grid>
                        )
                      );
                    })}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Grid container style={{ justifyContent: 'space-between' }}>
                  <Grid item xs={12} sm={6}>
                    Play History : {historyByIdData.length}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      onClick={() =>
                        navigate('/admincontrol/history', {
                          state: {
                            userIdFromParent: currentUserId,
                          },
                        })
                      }
                    >
                      <Typography style={{ textAlign: 'right' }}>
                        Go to History Table
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: 'column' }}>
                {historyByIdData?.length > 0 &&
                  historyByIdData.map((historyByIdObj) => {
                    return (
                      <Box mb={2}>
                        <Grid container spacing={1}>
                          {Object.keys(historyByIdObj).map((o) => {
                            return (
                              historyArr.includes(o) && (
                                <Grid item xs={12} sm={2}>
                                  <Box mb={1}>
                                    <Typography
                                      variant="body1"
                                      component="span"
                                    >
                                      <Box fontWeight="bold">{o}</Box>
                                    </Typography>
                                    <Typography variant="body1" noWrap={true}>
                                      <Box
                                        onClick={() =>
                                          o == 'qId' &&
                                          navigate('/admincontrol/question', {
                                            state: {
                                              userIdFromParent:
                                                historyByIdObj[o],
                                            },
                                          })
                                        }
                                      >
                                        {historyByIdObj[o]}
                                      </Box>
                                    </Typography>
                                  </Box>
                                </Grid>
                              )
                            );
                          })}
                        </Grid>
                      </Box>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Grid container style={{ justifyContent: 'space-between' }}>
                  <Grid item xs={12} sm={6}>
                    Transactions {transactionByIdData.length}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      onClick={() =>
                        navigate('/admincontrol/transaction', {
                          state: {
                            userIdFromParent: currentUserId,
                          },
                        })
                      }
                    >
                      <Typography style={{ textAlign: 'right' }}>
                        Go to Transaction Table
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails style={{ flexDirection: 'column' }}>
                {transactionByIdData?.length > 0 &&
                  transactionByIdData.map((historyByIdObj) => {
                    return (
                      <Box mb={2}>
                        <Grid container spacing={1}>
                          {Object.keys(historyByIdObj).map((o) => {
                            return (
                              transArr.includes(o) && (
                                <Grid item xs={12} sm={3}>
                                  <CustomTextField
                                    fullWidth
                                    type="text"
                                    inputLabel={o}
                                    placeholder={o}
                                    variant="filled"
                                    value={historyByIdObj[o]}
                                    // value={drawerData.points}
                                    disabled={true}
                                    // onChange={(event) => {
                                    //   setDrawerData({
                                    //     ...drawerData,
                                    //     points: event.target.value,
                                    //   });
                                    // }}
                                  />
                                </Grid>
                              )
                            );
                          })}
                        </Grid>
                      </Box>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Box>
    </AuthLayout>
  );
}

export default UserDetails;
