import { apiConstant } from '../services/apiConstants';
import useRequestHook from '../services/request';

const useNotificationApiHook = () => {
  const { Request } = useRequestHook();

  const getNotification = (data) => {
    return Request.get(apiConstant.notification, data);
  };
  const updateNotificationById = ({ notificationId, data }) => {
    // console.log('data', data);
    return Request.put(apiConstant.notification + '/' + notificationId, data);
  };

  const createNotification = (data) => {
    // console.log('data', data);
    return Request.post(apiConstant.notification, data);
  };

  const deleteNotificationById = (userId) => {
    // console.log('data', data);
    return Request.delete(apiConstant.notification + '/' + userId);
  };
  const deleteNotificatioHisById = (notificationId) => {
    // console.log('data', data);
    return Request.delete(apiConstant.notification + '/' + notificationId);
  };
  const sendNotification = (data) => {
    return Request.post(apiConstant.notification + '/send', data);
  };
  const sendNotificationResult = ({ notificationId, data }) => {
    return Request.post(
      apiConstant.notification + '/' + notificationId + '/sendresult',
      data
    );
  };
  return {
    getNotification,
    updateNotificationById,
    createNotification,
    deleteNotificationById,
    sendNotification,
    sendNotificationResult,
    deleteNotificatioHisById,
  };
};

export default useNotificationApiHook;
