import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { SelectedDropdown } from '../../../components/selectedDropdown';
import { filterQuesArr } from '../../../constant';
import { CustomTextField } from '../../../components/TextField';
import { CustomButton } from '../../../components/Button';
import { useMutation } from 'react-query';
import QuestionApiHook from '../../../hooks/QuestionApiHook';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import ProgressBar from '@ramonak/react-progress-bar';
import useThemeHook from '../../../hooks/ThemeHook';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export const CreateQuestion = (props) => {
  const { preview, setToggleDrawer, questionListRefech, currentQuestionData } =
    props;

  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [progressCount, setProgressCount] = useState(100);
  const [count, setCount] = React.useState(0);

  const countArr = Array.from(Array(currentQuestionData.duration).keys());
  const durationBreakArr = countArr
    .map((o) => ((o * 100) / currentQuestionData.duration).toFixed(0))
    .reverse();

  const { xs_layout } = useThemeHook();

  React.useEffect(() => {
    let subs = true;
    setTimeout(() => {
      if (subs) {
        if (count < currentQuestionData.duration) {
          setProgressCount(durationBreakArr[count]);
          setCount(count + 1);
        } else {
          // setProgressCount(0);
          // setCount(1);
        }
      }
    }, 1000);
    return () => {
      subs = false;
    };
  }, [count]);

  const { createQuestion, updateQuestionById, uploadImage } = QuestionApiHook();
  const initialObj = {
    courseObject: filterQuesArr?.courses[0],
    topicObject: filterQuesArr?.topics[0],
    standardObject: filterQuesArr?.standards[0],
    choiceObject: filterQuesArr?.choice[0],
    entryPointObject: filterQuesArr?.entryPointsArray[0],
    durationObject: filterQuesArr?.durationArray[0],
    // levelObject: filterQuesArr?.statusArray[1],
    levelObject: filterQuesArr?.level[1],
    questionType: 'text',

    courses: '',
    topics: '',
    standards: '',
    entryPoints: '',
    duration: '',
    status: 'Inactive',
    level: 'Easy',
    questionType: 'Text',
    questionLabel: '',
    question: '',
    questionImage: '',
    questionAudio: '',
    questionChoiceType: 'SCQ',
    questionOptions: [
      {
        label: 'Option 1',
        selected: false,
        option: '',
      },
      {
        label: 'Option 2',
        selected: false,
        option: '',
      },
      {
        label: 'Option 3',
        selected: false,
        option: '',
      },
      {
        label: 'Option 4',
        selected: false,
        option: '',
      },
    ],
  };

  const [pagedata, setPagedata] = useState(initialObj);
  const [showPreview, setShowPreview] = useState(preview ?? false);
  const [localImage, setLocalImage] = useState(null);

  useEffect(() => {
    if (Object.keys(currentQuestionData).length > 0) {
      setPagedata({
        ...initialObj,
        ...currentQuestionData,
        courseObject: filterQuesArr?.courses.filter(
          (o) => o.value === currentQuestionData.courses
        )[0],
        topicObject: filterQuesArr?.topics.filter(
          (o) => o.value === currentQuestionData.topics
        )[0],
        standardObject: filterQuesArr?.standards.filter(
          (o) => o.value === currentQuestionData.standards
        )[0],
        // choiceObject:filterQuesArr?.choice.filter((o)=>o.value === currentQuestionData.courses)[0],
        entryPointObject:
          filterQuesArr?.entryPointsArray.filter(
            (o) => o.value == currentQuestionData.entryPoints
          )[0] ?? 100,
        durationObject:
          filterQuesArr?.durationArray.filter(
            (o) => o.value == currentQuestionData.duration
          )[0] ?? 10,
        levelObject:
          filterQuesArr?.level.filter(
            (o) => o.value == currentQuestionData.level
          )[0] ?? 'Easy',
      });
    } else {
      setPagedata(initialObj);
    }
  }, [currentQuestionData]);

  const { mutate: createQuestionMutate, isLoading: deleteUserLoader } =
    useMutation(createQuestion, {
      onSuccess: (data) => onSuccessRegister(data),
      onError: (data) => onErrorResgister(data),
    });
  const { mutate: uploadImageMutate, isLoading: uploadImageLoader } =
    useMutation(uploadImage, {
      onSuccess: (data) => onSuccessImageUpload(data),
      onError: (data) => onErrorResgister(data),
    });
  const {
    mutate: updateQuestionByIdMutate,
    isLoading: updateQuestionByIdLoader,
  } = useMutation(updateQuestionById, {
    onSuccess: (data) => onSuccessRegister(data),
    onError: (data) => onErrorResgister(data),
  });

  const onErrorResgister = (error) => {
    console.log('error', error);
  };

  const onSuccessRegister = (data) => {
    if (data.data.status) {
      questionListRefech && questionListRefech();
      setToggleDrawer && setToggleDrawer(false);
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'success',
      });
    } else {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'error',
      });
    }
  };

  const onSuccessImageUpload = (data) => {
    setLocalImage(null);
    if (data?.data?.s3Url) {
      const pdata = {
        courses: pagedata.courseObject.value,
        topics: pagedata.topicObject.value,
        standards: pagedata.standardObject.value,
        entryPoints: pagedata.entryPointObject.value,
        duration: pagedata.durationObject.value,
        level: pagedata.levelObject.value,
        questionType: pagedata.questionType,
        questionLabel: pagedata.questionLabel,
        question: pagedata.question,
        questionImage: data.data.s3Url,
        questionAudio: pagedata.questionAudio,
        questionChoiceType: pagedata.questionChoiceType,
        questionOptions: pagedata.questionOptions,
      };
      Object.keys(currentQuestionData).length == 0
        ? createQuestionMutate(pdata)
        : updateQuestionByIdMutate({
            questionId: currentQuestionData.questionId,
            data: pdata,
          });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setLocalImage(file);
    if (file) {
      // uploadImageMutate({ file: file });
      const reader = new FileReader();
      reader.onload = (e) => {
        setPagedata({ ...pagedata, questionImage: e.target.result });
      };
      reader.readAsDataURL(file);
    } else {
      setPagedata({ ...pagedata, questionImage: null });
    }
  };

  const checkboxClickHandler = (index, event) => {
    const localArray = pagedata?.questionOptions?.map((item, currentIndex) => {
      if (
        pagedata.questionType == 'Text' &&
        pagedata.questionChoiceType == 'MCQ'
      ) {
        return index === currentIndex
          ? { ...item, selected: event.target.checked }
          : item;
      } else if (
        pagedata.questionType == 'Image' &&
        pagedata.questionChoiceType == 'MCQ'
      ) {
        return index === currentIndex
          ? { ...item, selected: event.target.checked }
          : item;
      } else {
        return {
          ...item,
          selected: index === currentIndex ? event.target.checked : false,
        };
      }
    });
    setPagedata({ ...pagedata, questionOptions: localArray });
  };

  const textFiledClickHandler = (index, event) => {
    const localArray = pagedata?.questionOptions?.map((item, currentIndex) => {
      return index === currentIndex
        ? { ...item, option: event.target.value }
        : item;
    });
    setPagedata({ ...pagedata, questionOptions: localArray });
  };

  const scqClickHandler = () => {
    const updatedQuestionOptions = pagedata?.questionOptions?.map((item) => {
      return { ...item, selected: null };
    });
    setPagedata({
      ...pagedata,
      questionChoiceType: 'SCQ',
      questionOptions: updatedQuestionOptions,
    });
  };

  const mcqClickHandler = () => {
    const updatedQuestionOptions = pagedata?.questionOptions?.map((item) => {
      return { ...item, selected: null };
    });
    setPagedata({
      ...pagedata,
      questionChoiceType: 'MCQ',
      questionOptions: updatedQuestionOptions,
    });
  };

  const createQuestionHandler = () => {
    const pdata = {
      courses: pagedata.courseObject.value,
      topics: pagedata.topicObject.value,
      standards: pagedata.standardObject.value,
      entryPoints: pagedata.entryPointObject.value,
      duration: pagedata.durationObject.value,
      level: pagedata.levelObject.value,
      questionType: pagedata.questionType,
      questionLabel: pagedata.questionLabel,
      question: pagedata.question,
      questionImage: '',
      questionAudio: pagedata.questionAudio,
      questionChoiceType: pagedata.questionChoiceType,
      questionOptions: pagedata.questionOptions,
    };
    pagedata.questionType == 'Text'
      ? createQuestionMutate(pdata)
      : uploadImageMutate({ file: localImage, type: 'file' });
  };
  const updateQuestionHandler = () => {
    localImage
      ? uploadImageMutate({ file: localImage, type: 'file' })
      : updateQuestionByIdMutate({
          questionId: currentQuestionData.questionId,
          data: {
            courses: pagedata.courseObject.value,
            topics: pagedata.topicObject.value,
            standards: pagedata.standardObject.value,
            entryPoints: pagedata.entryPointObject.value,
            duration: pagedata.durationObject.value,
            level: pagedata.levelObject.value,
            questionType: pagedata.questionType,
            questionLabel: pagedata.questionLabel,
            question: pagedata.question,
            questionImage: pagedata.questionImage,
            questionAudio: pagedata.questionAudio,
            questionChoiceType: pagedata.questionChoiceType,
            questionOptions: pagedata.questionOptions,
          },
        });
  };

  const showPreviewHandler = () => {
    setShowPreview(true);
  };

  useEffect(() => {
    if (progressCount == 0) {
      setTimeout(() => {
        setToggleDrawer(false);
      }, 1000);
    }
  }, [progressCount]);

  return (
    // <AuthLayout>
    <>
      <Box p={1} height={1}>
        <Typography variant="h6">
          <ArrowBackIcon onClick={() => setToggleDrawer(false)} />{' '}
          {showPreview ? 'Question Preview' : 'Add Question'}
        </Typography>
        <Divider variant="fullWidth" style={{ marginBottom: 20 }} />
        <ProgressBar completed={progressCount} bgColor="#dfce44" />
        <Typography variant="h6">{progressCount}</Typography>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%', paddingTop: 20 }}
        >
          {!showPreview ? (
            <Grid item xs="12">
              <SelectedDropdown
                subjectTrue
                topicTrue
                classTrue
                entryPoint
                duration
                level
                // status
                pagedata={pagedata}
                setPagedata={setPagedata}
              />
              <Grid container item pt={'20px'}>
                <Grid item xs="6" style={{ cursor: 'pointer' }}>
                  <Typography
                    onClick={() =>
                      setPagedata({ ...pagedata, questionType: 'Text' })
                    }
                    className={
                      pagedata.questionType == 'Text' ? 'class-txt' : null
                    }
                    sx={{ padding: '14px', borderRadius: '10px 10px 0px 0px' }}
                  >
                    Text
                  </Typography>
                </Grid>
                <Grid item xs="6" style={{ cursor: 'pointer' }}>
                  <Typography
                    onClick={() =>
                      setPagedata({ ...pagedata, questionType: 'Image' })
                    }
                    className={
                      pagedata.questionType == 'Image' ? 'class-img' : null
                    }
                    sx={{ padding: '14px', borderRadius: '10px 10px 0px 0px' }}
                  >
                    Image / Audio
                  </Typography>
                </Grid>
                {pagedata?.questionType == 'Text' && (
                  <Grid item xs="12" className="txt-img-field">
                    <CustomTextField
                      fullWidth
                      type="text"
                      multiline
                      placeholder="Start writing your question"
                      onChange={(e) => {
                        setPagedata({ ...pagedata, question: e.target.value });
                      }}
                      className="question-textfield"
                      value={pagedata.question}
                    />
                  </Grid>
                )}
                {pagedata?.questionType == 'Image' && (
                  <Grid item xs="12" className="image-field">
                    <Typography className="question-lable-txt">
                      Question Label
                    </Typography>
                    <CustomTextField
                      fullWidth
                      type="text"
                      placeholder="Start writing your question"
                      onChange={(e) => {
                        setPagedata({ ...pagedata, question: e.target.value });
                      }}
                      value={pagedata.question}
                    />
                    {pagedata?.questionImage && (
                      <Grid container item justifyContent={'center'}>
                        <Grid
                          item
                          xs="6"
                          sx={{
                            width: '50%',
                            height: '300px',
                            padding: '10px 0px',
                          }}
                        >
                          <img
                            src={pagedata.questionImage}
                            alt="Uploaded"
                            style={{ width: '100%', height: '100%' }}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Box p={'10px 0px'}>
                      <Button
                        sx={{ background: '#ccc', fontWeight: 'bolder' }}
                        component="label"
                        variant="contained"
                        type="file"
                        fullWidth
                      >
                        Upload file
                        <input
                          type="file"
                          accept="*"
                          hidden
                          onChange={handleFileChange}
                        />
                      </Button>
                    </Box>
                  </Grid>
                )}
                <Grid item xs="12" mt={'10px'}>
                  <Grid container item justifyContent={'space-evenly'}>
                    <Grid item xs="5">
                      <Typography
                        textAlign={'center'}
                        onClick={scqClickHandler}
                        className={
                          pagedata.questionChoiceType == 'SCQ'
                            ? 'question-choicetype-txt'
                            : 'question-choicetype-txt-default'
                        }
                        sx={{
                          padding: '10px 0px',
                          borderRadius: '10px',
                          cursor: 'pointer',
                          width: '80%',
                        }}
                      >
                        SCQ
                      </Typography>
                    </Grid>
                    <Grid item xs="5">
                      <Typography
                        textAlign={'center'}
                        onClick={mcqClickHandler}
                        className={
                          pagedata.questionChoiceType == 'MCQ'
                            ? 'question-choicetype-txt'
                            : 'question-choicetype-txt-default'
                        }
                        sx={{
                          padding: '10px 0px',
                          borderRadius: '10px',
                          cursor: 'pointer',
                          width: '80%',
                        }}
                      >
                        MCQ
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs="12" p={'10px 0px'}>
                  {pagedata?.questionOptions?.length > 0 &&
                    pagedata?.questionOptions?.map((item, index) => {
                      return (
                        <Grid
                          container
                          item
                          alignItems="center"
                          spacing={1}
                          key={index}
                          p={1}
                        >
                          <Grid item xs="2" sm="1" md="2" lg="1">
                            <Checkbox
                              checked={item.selected}
                              onChange={(event) =>
                                checkboxClickHandler(index, event)
                              }
                            />
                          </Grid>
                          <Grid item xs="10" sm="11" md="10" lg="11">
                            <CustomTextField
                              className={
                                item.selected ? 'selected-checkbox' : ''
                              }
                              fullWidth
                              type="text"
                              value={item.option}
                              placeholder={item?.label}
                              onChange={(event) =>
                                textFiledClickHandler(index, event)
                              }
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
                <Grid item xs="12" style={{ marginTop: 50 }}>
                  <CustomButton
                    variant="contained"
                    btnText="Preview Question"
                    fullWidth
                    btnClass={{ backgroundColor: '#4545A5', color: '#fff' }}
                    onClick={showPreviewHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs="12" className="preview-wrap">
              <Grid container item justifyContent={'center'}>
                <Grid item xs="12">
                  <Grid container item justifyContent={'space-between'}>
                    {pagedata?.courseObject?.value && (
                      <Typography>{`${'#'}${
                        pagedata?.courseObject?.value
                      }`}</Typography>
                    )}
                    {pagedata?.topicObject?.value && (
                      <Typography>{`${'#'}${
                        pagedata?.topicObject?.value
                      }`}</Typography>
                    )}
                    {pagedata?.standardObject?.value && (
                      <Typography>{`${'#'}${
                        pagedata?.standardObject?.value
                      }`}</Typography>
                    )}
                  </Grid>
                  <Grid container item justifyContent={'space-between'}>
                    <Typography>
                      Entry Point : {pagedata?.entryPointObject?.value}
                    </Typography>
                    <Typography>
                      Duration : {pagedata?.durationObject?.value}
                    </Typography>
                  </Grid>
                  <Box className="question-wrap">
                    <Typography p={1} color={'#6b6b6b'} fontWeight={'bolder'}>
                      {pagedata?.question}
                    </Typography>
                    {pagedata?.questionImage && (
                      <Box p={'0px 10px'}>
                        <img
                          src={pagedata?.questionImage}
                          alt="Uploaded"
                          style={{
                            width: '100%',
                            height: '250px',
                            borderRadius: '0px 0px 10px 10px',
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  {pagedata?.questionOptions?.length > 0 &&
                    pagedata?.questionOptions?.map((item, index) => {
                      return (
                        <Grid
                          container
                          item
                          alignItems="center"
                          spacing={1}
                          key={index}
                          p={1}
                          className="preview-checkbox-field"
                        >
                          {/* <Grid item xs="2" sm="1" md="2" lg="1.5">
                          <Checkbox
                            disabled
                            checked={item.selected}
                            onChange={(event) =>
                              checkboxClickHandler(index, event)
                            }
                          />
                        </Grid> */}
                          <Grid item xs="12">
                            <CustomTextField
                              className={
                                item.selected == true && 'selected-checkbox'
                              }
                              fullWidth
                              type="text"
                              disabled
                              value={item.option}
                              placeholder={item?.label}
                              onChange={(event) =>
                                textFiledClickHandler(index, event)
                              }
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  <Grid
                    container
                    item
                    justifyContent={'space-around'}
                    p={'10px 0px'}
                    className="delete-view-btn"
                  >
                    <Grid item xs="5">
                      <CustomButton
                        variant="contained"
                        btnText="Back"
                        fullWidth
                        onClick={() => setShowPreview(false)}
                      />
                    </Grid>
                    <Grid item xs="5">
                      {Object.keys(currentQuestionData).length > 0 ? (
                        <CustomButton
                          variant="contained"
                          btnText="Update Question"
                          fullWidth
                          onClick={updateQuestionHandler}
                        />
                      ) : (
                        <CustomButton
                          variant="contained"
                          btnText="Create Question"
                          fullWidth
                          onClick={createQuestionHandler}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      {Object.keys(snakeBarProps).length > 0 && (
        <CustomSnackbar
          {...snakeBarProps}
          setSnakeBarProps={setSnakeBarProps}
        />
      )}
    </>
    // </AuthLayout>
  );
};
