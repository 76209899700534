import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import AuthLayout from '../AuthLayout';
import { useMutation, useQuery } from 'react-query';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  Typography,
} from '@mui/material';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import HistoryApiHook from '../../../hooks/HistoryApiHook';
import moment from 'moment';
import TransactionApiHook from '../../../hooks/TransactionApiHook';
import useThemeHook from '../../../hooks/ThemeHook';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomTextField } from '../../../components/TextField';
import { CustomButton } from '../../../components/Button';
import { afterValidate } from '../../../utils/CommonLogic';
import { useLocation } from 'react-router-dom';

const Transaction = (props) => {
  const location = useLocation();
  const { state } = location;
  const { userIdFromParent = '' } = state || {};
  const { getTransaction, deleteTransactionById, updateTransactionById } =
    TransactionApiHook();
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [deleteConfirmFlag, setDeleteConfirmFlag] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [transactionType, setTransactionType] = useState('Deposit');
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [submitFlag, setsubmitFlag] = useState(false);
  const [setBonus, setSetBonus] = useState(0);
  const { xs_layout } = useThemeHook();

  const initialDrawerData = {
    completedStatus: 'pending',
  };

  const withdrawalStatusArr = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Done', value: 'Done' },
    { label: 'Cancelled', value: 'Cancelled' },
  ];

  const [drawerData, setDrawerData] = useState(initialDrawerData);

  const {
    data: userArr,
    isLoading: userDataLoader,
    refetch: userListRefetch,
  } = useQuery(['getTransaction', transactionType], () => getTransaction(), {
    // enabled: true,
    // refetchInterval: 5000,
    // refetchIntervalInBackground: true,
  });

  const { mutate: deleteUserMutate, isLoading: deleteUserLoader } = useMutation(
    deleteTransactionById,
    {
      onSuccess: (data) => onSuccessRegister(data),
      onError: (data) => onErrorResgister(data),
    }
  );

  const {
    mutate: updateTransactionByIdMutate,
    isLoading: updateTransactionByIdLoader,
  } = useMutation(updateTransactionById, {
    onSuccess: (data) => onSuccessRegister(data),
    onError: (data) => onErrorResgister(data),
  });

  const onSuccessRegister = (data) => {
    if (data.data.status) {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message ?? 'Transaction deleted successfully',
        type: 'success',
      });
      userListRefetch();
      setToggleDrawer(false);
    } else {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'error',
      });
    }
  };

  const onErrorResgister = (data) => {
    console.log('data', data);
  };

  const userData = userArr?.data?.data ?? [];

  const handleCancel = () => {
    setDeleteConfirmFlag(false);
  };

  const handleOk = () => {
    setDeleteConfirmFlag(false);
    deleteUserMutate(currentId);
  };

  const setTransctionType = (type) => {
    setTransactionType(type);
    userListRefetch();
  };

  const submitHandler = () => {
    setsubmitFlag(true);
    afterValidate(afterValidateCallBack);
  };

  const afterValidateCallBack = (second) => {
    setsubmitFlag(false);
    updateTransactionByIdMutate(drawerData);
  };

  const setTransferValue = (second) => {
    console.log('drawerData', drawerData);

    const pointToInrValue = drawerData.pointToInr.split('-');

    const bonusAdjustedAmt =
      drawerData.bonusAmt > parseInt(drawerData.amount)
        ? drawerData.bonusAmt - parseInt(drawerData.amount)
        : parseInt(drawerData.amount) - drawerData.bonusAmt;

    const tranferAmt = (bonusAdjustedAmt / 100) * pointToInrValue[1];

    return (tranferAmt - [tranferAmt * 0.1] - [tranferAmt * 0.02]).toFixed(2);
  };

  return (
    <AuthLayout>
      {deleteUserLoader ||
        updateTransactionByIdLoader ||
        (userDataLoader && (
          <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        ))}
      <Box style={{ textAlign: 'center' }}>
        {['Deposit', 'Withdrawal'].map((o) => {
          return (
            <Button
              style={{ color: transactionType == o ? '#FCBD42' : '#000' }}
              onClick={() => setTransctionType(o)}
            >
              {o}
            </Button>
          );
        })}
      </Box>
      <Box sx={{ width: xs_layout ? '100%' : '90%', margin: '20px auto' }}>
        <MaterialTable
          columns={[
            {
              title: 'UId',
              field: 'userId',
              // defaultGroupOrder: 0
            },
            { title: 'Amount', field: 'amount' },
            { title: 'Status', field: 'completedStatus' },

            { title: 'Mobile', field: 'mobilenumber' },
            { title: 'Email', field: 'email' },
            {
              title: 'Date',
              field: 'date',
              // defaultGroupOrder: 0,
              render: (rowData) =>
                moment(rowData.date).format('DD-MM-YYYY_hh:MM:SS A'),
              cellStyle: { width: 250 },
            },

            {
              title: 'PaymentId/ContactId ',
              field: 'paymentId',
            },
          ]}
          data={userData.filter((o) => o.status == transactionType)}
          title="Transaction"
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh',
              isFreeAction: true,
              onClick: (event) => {
                userListRefetch();
              },
            },
            (rowData) => ({
              icon: 'delete',
              tooltip: 'Delete User',
              onClick: (event, rowData) => {
                setDeleteConfirmFlag(true);
                setCurrentId(rowData.paymentId);
              },
              disabled: rowData.birthYear < 2000,
            }),
          ]}
          onRowClick={(event, rowData, togglePanel) => {
            setDrawerData({
              ...rowData,
            });
            rowData.status == 'Withdrawal' && setToggleDrawer(true);
          }}
          options={{
            actionsColumnIndex: 0,
            grouping: true,
            pageSize: 10,
            search: true,
            searchText: '' + userIdFromParent,
          }}
        />
      </Box>
      {toggleDrawer && (
        <Drawer
          open={true}
          anchor="right"
          onClose={() => setToggleDrawer(false)}
        >
          <Box sx={[xs_layout ? { p: 1 } : { p: 2, width: '300px' }]}>
            <Grid container style={{}} spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ textJustify: 'center' }}>
                  <ArrowBackIcon
                    onClick={() => setToggleDrawer(false)}
                    style={{ marginRight: 10 }}
                  />
                  Update Transaction
                </Typography>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="userId"
                  placeholder="userId"
                  variant="filled"
                  value={drawerData.userId}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomTextField
                  fullWidth
                  select
                  selectArr={withdrawalStatusArr}
                  selectDefaultOption={withdrawalStatusArr.filter(
                    (o) => o.value == drawerData.completedStatus
                  )}
                  type="text"
                  inputLabel="Completed Status"
                  placeholder="Completed Status"
                  variant="filled"
                  value={drawerData.completedStatus}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      completedStatus: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="TransId"
                  placeholder="TransId"
                  variant="filled"
                  value={drawerData.transactionRefId}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      transactionRefId: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="pointToInr"
                  placeholder="pointToInr"
                  variant="filled"
                  value={drawerData.pointToInr}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="Transfer Amount"
                  placeholder="Transfer Amount"
                  variant="filled"
                  value={setTransferValue()}
                />
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                sx={{ display: 'flex' }}
              >
                <CustomButton
                  btnText={`Update Transaction`}
                  color="primary"
                  variant="contained"
                  onClick={submitHandler}
                />
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      )}
      {deleteConfirmFlag && (
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth="xs"
          open={true}
        >
          <DialogTitle>Do you want to delete this History?</DialogTitle>
          <DialogContent dividers>
            <Typography fontWeight="700">History Id {currentId}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Ok</Button>
          </DialogActions>
        </Dialog>
      )}
      {Object.keys(snakeBarProps).length > 0 && (
        <CustomSnackbar
          {...snakeBarProps}
          setSnakeBarProps={setSnakeBarProps}
        />
      )}
    </AuthLayout>
  );
};

export default Transaction;
