import React from 'react';
import HomeLayout from './HomeLayout';
import AppInfoLayout from './AppInfoLayout';
import { Grid } from '@material-ui/core';
import { useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

function Refund() {
  const theme = useTheme();
  const mdLayout = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <HomeLayout>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: 'calc(100vh - 44px)', padding: 16 }}
      >
        <Grid container item xs={12} md={10} justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <AppInfoLayout />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            style={{ marginBottom: mdLayout ? 0 : 100 }}
          >
            <section class="container" style={{ marginBottom: 100 }}>
              <h1>Refund, Redeem, and Cancellation Policy</h1>

              <div class="section">
                <h2>1. Refund Policy</h2>
                <ul>
                  <li>
                    <strong>No Refunds on Points:</strong> Points purchased or
                    earned within the InstaJawab app are non-refundable. Once
                    points are credited to your account, they cannot be
                    exchanged for cash or refunded in any form.
                  </li>
                  <li>
                    <strong>Subscription Renewal:</strong> If you choose to
                    renew your subscription by purchasing additional points,
                    please note that these transactions are final and
                    non-refundable.
                  </li>
                </ul>
              </div>

              <div class="section">
                <h2>2. Redeem Policy</h2>
                <ul>
                  <li>
                    <strong>Redeeming Points:</strong> Users can redeem their
                    earned points by converting them into money. 100 earned
                    points is equal to 10 rupees. This ratio may change at any
                    time. The redemption process requires users to provide a
                    valid UPI ID.
                  </li>
                  <li>
                    <strong>Redemption Charges:</strong> A 12% transfer charge
                    will be applied to the total amount redeemed. This fee will
                    be deducted before the money is transferred to your bank
                    account.
                  </li>
                  <li>
                    <strong>Processing Time:</strong> The redemption process may
                    take between 7 to 10 business days to complete. Please
                    ensure that the UPI ID provided is accurate to avoid any
                    delays or issues.
                  </li>
                  <li>
                    <strong>Minimum Redemption:</strong> Users can redeem points
                    only if the total amount after charges meets the minimum
                    threshold specified within the app.
                  </li>
                  <li>
                    <strong>Redemption Adjustment:</strong> Please note that
                    points earned from promo codes and bonuses may be adjusted
                    upon withdrawal.
                  </li>
                </ul>
              </div>

              <div class="section">
                <h2>3. Cancellation Policy</h2>
                <ul>
                  <li>
                    <strong>Account Suspension/Termination:</strong> InstaJawab
                    reserves the right to suspend or terminate user accounts at
                    any time without prior notice for any violation of the app’s
                    terms and conditions or any suspicious activity.
                  </li>
                  <li>
                    <strong>Automatic Cancellation:</strong> If your points
                    balance reaches zero and you do not renew your subscription
                    by purchasing additional points, your account may be
                    automatically canceled, and you may lose access to the app’s
                    features.
                  </li>
                  <li>
                    <strong>Cancellation of Redeem Requests:</strong> Once a
                    redeem request has been submitted, the request may be
                    canceled only if an incorrect UPI ID was provided or if a
                    server error occurred during the redemption process
                  </li>
                </ul>
              </div>

              <div class="section">
                <h2>4. General Terms</h2>
                <ul>
                  <li>
                    <strong>Changes to Policy:</strong> InstaJawab reserves the
                    right to modify this Refund, Redeem, and Cancellation Policy
                    at any time. Any changes will be communicated to users
                    through the app or via email.
                  </li>
                  <li>
                    <strong>Contact Us:</strong> For any queries or issues
                    related to refunds, redeeming points, or account
                    cancellations, please contact us at{' '}
                    <a href="mailto:instajawab@gmail.com">
                      instajawab@gmail.com
                    </a>
                    .
                  </li>
                </ul>
              </div>
            </section>
          </Grid>
        </Grid>
      </Grid>
    </HomeLayout>
  );
}

export default Refund;
