import React from 'react';
import HomeLayout from './HomeLayout';
import AppInfoLayout from './AppInfoLayout';
import { Grid } from '@material-ui/core';
import { useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

function ContactUs() {
  const theme = useTheme();
  const mdLayout = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <HomeLayout>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: 'calc(100vh - 44px)', padding: 16 }}
      >
        <Grid container item xs={12} md={10} justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <AppInfoLayout />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            style={{ marginBottom: mdLayout ? 0 : 100 }}
          >
            <section class="container">
              <h2>Contact Us - Insta Jawab</h2>
              <p>
                <strong>Email:</strong> instajawab@gmail.com
              </p>

              <p>
                <strong>Address:</strong>
              </p>
              <ul>
                <li>Digiappstore It Solutions Pvt. Ltd.</li>
                <li>Delhi, India - 110096</li>
              </ul>

              <h2>Office Hours:</h2>
              <ul>
                <li>Monday to Saturday: 9:00 AM to 6:00 PM (IST)</li>
                <li>Sunday: Closed</li>
              </ul>

              <h2>Support:</h2>
              <p>
                For any technical issues or support requests, please email us at
                instajawab@gmail.com or use the support form on our website.
              </p>

              {/* <h2>Social Media:</h2>
              <p>
                Follow us on our social media channels for the latest updates
                and news:
              </p>
              <div class="social-media">
                <a href="https://facebook.com/instajawab">Facebook</a>
                <a href="https://twitter.com/instajawab">Twitter</a>
                <a href="https://instagram.com/instajawab">Instagram</a>
              </div> */}
            </section>
          </Grid>
        </Grid>
      </Grid>
    </HomeLayout>
  );
}

export default ContactUs;
