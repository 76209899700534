export const chipStyle = {
    MuiChip: {
        styleOverrides: {
            root: {
                '&.brb_0': {
                    borderRadius: "10px 10px 0px 0px !important",
                }
            }
        }
    }
}

