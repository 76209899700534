import { apiConstant } from '../services/apiConstants';
import useRequestHook from '../services/request';

const HistoryApiHook = () => {
  const { Request } = useRequestHook();

  const getHistory = (data) => {
    return Request.get(apiConstant.playhistory, data);
  };

  const deleteHistoryById = (playhistoryId) => {
    return Request.delete(apiConstant.playhistory + '/' + playhistoryId);
  };
  const getHistoryByUserId = (userId) => {
    return Request.get(apiConstant.playhistory + '/user/' + userId);
  };
  return {
    getHistory,
    deleteHistoryById,
    getHistoryByUserId,
  };
};

export default HistoryApiHook;
