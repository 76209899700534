import axios from 'axios';
import { apiConstant } from '../services/apiConstants';
import useRequestHook from '../services/request';

const TransactionApiHook = () => {
  const { Request } = useRequestHook();

  const getTransaction = (data) => {
    return Request.get(apiConstant.transaction + '/all', data);
  };
  const getTransactionById = (userId) => {
    return Request.get(apiConstant.transaction + '/' + userId);
  };

  const deleteTransactionById = (transactionId) => {
    return Request.delete(apiConstant.transaction + '/' + transactionId);
  };
  const updateTransactionById = (data) => {
    return Request.put(apiConstant.transaction + '/' + data.paymentId, data);
  };

  const createPayout = async (data) => {
    const rpUrl = 'https://api.razorpay.com/v1';
    const rpToken =
      'Basic cnpwX3Rlc3RfajZXcVZZNFdNcUZQVkw6SDZZalFjR1MyVldYdUNqbHNja2hxTDV1';
    const rpHeader = { headers: { Authorization: rpToken } };
    return axios.post(`${rpUrl}/payout`, data, rpHeader);
  };

  return {
    getTransaction,
    deleteTransactionById,
    createPayout,
    updateTransactionById,
    getTransactionById,
  };
};

export default TransactionApiHook;
