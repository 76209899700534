import React from 'react';
import HomeLayout from './HomeLayout';
import AppInfoLayout from './AppInfoLayout';
import { Grid } from '@material-ui/core';
import { useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

function PrivacyPolicy() {
  const theme = useTheme();
  const mdLayout = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <HomeLayout>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: 'calc(100vh - 44px)', padding: 16 }}
      >
        <Grid container item xs={12} md={10} justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <AppInfoLayout />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            style={{ marginBottom: mdLayout ? 0 : 100 }}
          >
            <div class="container" style={{ marginBottom: 100 }}>
              <h1>Privacy Policy</h1>

              <h2>1. Introduction</h2>
              <p>
                Welcome to InstaJawab, a platform designed to provide users with
                the opportunity to earn rewards through a quick reward challenge
                by answering questions. This privacy policy outlines how we
                collect, use, and protect your personal information while you
                interact with InstaJawab.
              </p>

              <h2>2. Information We Collect</h2>
              <ul>
                <li>
                  <strong>Personal Information:</strong> When you register for
                  InstaJawab, we collect personal information such as your name,
                  email address, mobilenumber and UPI ID, which is necessary for
                  account creation, verification, and reward redemption.
                </li>
                <li>
                  <strong>Usage Data:</strong> We collect data on how you use
                  the app, including the questions you answer, points you earn,
                  and the time spent on the platform. This data helps us improve
                  your experience and provide relevant features.
                </li>
                <li>
                  <strong>Transaction Data:</strong> Information related to your
                  transactions, such as points redeemed and rewards transferred
                  to your bank account, is recorded for security and audit
                  purposes.
                </li>
              </ul>

              <h2>3. How We Use Your Information</h2>
              <ul>
                <li>
                  <strong>To Operate the App:</strong> Your personal and usage
                  data are used to facilitate the primary functions of
                  InstaJawab, such as participating in challenges, tracking
                  points, and processing rewards.
                </li>
                <li>
                  <strong>To Improve Our Services:</strong> We analyze usage
                  data to enhance app functionality, introduce new features, and
                  ensure a smooth user experience.
                </li>
                <li>
                  <strong>To Communicate with You:</strong> We may use your
                  contact information to send notifications about live
                  questions, updates, and promotional offers. You can opt out of
                  promotional communications at any time.
                </li>
                <li>
                  <strong>To Process Transactions:</strong> Your UPI ID and
                  transaction data are used to process reward redemptions. We
                  ensure that all financial data is handled securely.
                </li>
              </ul>

              <h2>4. Sharing Your Information</h2>
              <ul>
                <li>
                  <strong>Third-Party Service Providers:</strong> We may share
                  your information with third-party service providers who assist
                  us in operating the app, processing payments, or conducting
                  analytics. These providers are required to protect your
                  information and use it only for the purposes we specify.
                </li>
                <li>
                  <strong>Legal Requirements:</strong> We may disclose your
                  information if required by law or in response to valid
                  requests by public authorities (e.g., a court or government
                  agency).
                </li>
              </ul>

              <h2>5. Security of Your Information</h2>
              <p>
                We take the security of your personal information seriously and
                implement appropriate technical and organizational measures to
                protect it from unauthorized access, alteration, or destruction.
                However, no method of transmission over the internet or method
                of electronic storage is completely secure, so we cannot
                guarantee its absolute security.
              </p>

              <h2>6. Your Rights</h2>
              <ul>
                <li>
                  <strong>Access and Update:</strong> You have the right to
                  access and update your personal information at any time
                  through your account settings.
                </li>
                <li>
                  <strong>Deletion:</strong> You can request the deletion of
                  your account and personal information by contacting us at{' '}
                  <a href="mailto:instajawab@gmail.com">instajawab@gmail.com</a>
                  .
                </li>
                <li>
                  <strong>Opt-Out:</strong> You can opt out of receiving
                  promotional communications by following the instructions
                  provided in the messages or by contacting us directly.
                </li>
              </ul>

              <h2>7. Children’s Privacy</h2>
              <p>
                InstaJawab is not intended for use by individuals under the age
                of 18. We do not knowingly collect personal information from
                children under 18. If we become aware that a child under 18 has
                provided us with personal information, we will take steps to
                delete such information.
              </p>

              <h2>8. Changes to This Privacy Policy</h2>
              <p>
                We may update our privacy policy from time to time. Any changes
                will be posted on this page, and we will notify you of
                significant changes through the app or via email.
              </p>

              <h2>9. Contact Us</h2>
              <p>
                If you have any questions or concerns about this privacy policy
                or how your information is handled, please contact us at{' '}
                <a href="mailto:instajawab@gmail.com">instajawab@gmail.com</a>.
                We are committed to addressing any issues you may have and
                ensuring your privacy is protected.
              </p>

              <h2>10. Account Suspension and Termination</h2>
              <p>
                InstaJawab reserves the right to suspend or terminate any user
                account at any time, with or without notice, if we believe the
                user has violated our terms of service or engaged in fraudulent
                activity.
              </p>

              <h2>11. Subscription Validity</h2>
              <p>
                Your subscription to InstaJawab is valid for a lifetime or until
                your points balance reaches zero. If your points balance reaches
                zero, you will need to renew your subscription by purchasing
                additional points.
              </p>

              <p>
                By using InstaJawab, you agree to the terms of this privacy
                policy and consent to the collection, use, and sharing of your
                information as described above.
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </HomeLayout>
  );
}

export default PrivacyPolicy;
