import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

export const SideMenu = [
  {
    icon: (
      <AccountCircleOutlinedIcon sx={{ color: '#FCBD42', fontWeight: '600' }} />
    ),
    label: 'All',
    route: '/admincontrol/userdetails',
  },
  {
    icon: (
      <AccountCircleOutlinedIcon sx={{ color: '#FCBD42', fontWeight: '600' }} />
    ),
    label: 'Question',
    route: '/admincontrol/question',
  },

  {
    icon: (
      <AutoAwesomeMosaicOutlinedIcon
        sx={{ color: '#FCBD42', fontWeight: '600' }}
      />
    ),
    label: 'User',
    route: '/admincontrol/user',
  },
  {
    icon: (
      <CalendarMonthOutlinedIcon sx={{ color: '#FCBD42', fontWeight: '600' }} />
    ),
    label: 'Histroy',
    route: '/admincontrol/history',
  },
  {
    icon: (
      <WorkspacePremiumOutlinedIcon
        sx={{ color: '#FCBD42', fontWeight: '600' }}
      />
    ),
    label: 'Transaction',
    route: '/admincontrol/transaction',
  },
  {
    icon: (
      <WorkspacePremiumOutlinedIcon
        sx={{ color: '#FCBD42', fontWeight: '600' }}
      />
    ),
    label: 'Configrution',
    route: '/admincontrol/configuration',
  },
  {
    icon: (
      <WorkspacePremiumOutlinedIcon
        sx={{ color: '#FCBD42', fontWeight: '600' }}
      />
    ),
    label: 'Notification',
    route: '/admincontrol/notification',
  },
];

export const footerArr = [
  {
    label: 'Home',
    route: '/',
  },
  {
    label: 'About Us',
    route: 'about-us',
  },
  {
    label: 'Privacy Policy',
    route: 'privacy-policy',
  },
  {
    label: 'Term and Condition',
    route: 'tnc',
  },
  {
    label: 'Refund & Cancellation',
    route: 'refund-and-cancellation',
  },
  {
    label: 'Contact Us',
    route: 'contact-us',
  },
];

export const chipMappedNameArr = [
  {
    label: 'Courses',
    mappedKey: 'courses',
  },
  {
    label: 'Topis',
    mappedKey: 'topics',
  },
  {
    label: 'Standards',
    mappedKey: 'standards',
  },
  {
    label: 'Choice',
    mappedKey: 'choice',
  },
  {
    label: 'Question Point',
    mappedKey: 'entryPointsArray',
  },
  {
    label: 'Duration',
    mappedKey: 'durationArray',
  },
  {
    label: 'Status',
    mappedKey: 'statusArray',
  },
  {
    label: 'Default Duration',
    mappedKey: 'defaultDuration',
  },
  {
    label: 'Duration Value',
    mappedKey: 'durationValue',
  },
  {
    label: 'User Initial Points',
    mappedKey: 'initialUserPoints',
  },
  {
    label: 'Min Deposit',
    mappedKey: 'minDeposit',
  },
  {
    label: 'Min Withdrawal',
    mappedKey: 'minWithdrawal',
  },
  {
    label: 'Default Question Attempt Limit',
    mappedKey: 'defaultQuestionAttemptLimit',
  },
  {
    label: 'Default Plan',
    mappedKey: 'defaultPlan',
  },
  {
    label: 'Version',
    mappedKey: 'version',
  },
  {
    label: 'Default Question Points',
    mappedKey: 'defaultQuestionPoints',
  },
  {
    label: 'Default Question Points Value',
    mappedKey: 'defaultQuestionPointsValue',
  },
];
