import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import AuthLayout from '../AuthLayout';
import { useMutation, useQuery } from 'react-query';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  Typography,
} from '@mui/material';
// import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import { Carousel } from 'react-responsive-carousel';

import { CustomTextField } from '../../../components/TextField';
import { getErrorMsz } from '../../../utils/validator';
import { afterValidate } from '../../../utils/CommonLogic';
import { CustomButton } from '../../../components/Button';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import useUnAuthCrudApiHook from '../../../hooks/UnAuthApiHook';
import UserApiHook from '../../../hooks/UserApiHook';
import QuestionApiHook from '../../../hooks/QuestionApiHook';
import { CreateQuestion } from '../question/Create';
import { DropzoneArea } from 'material-ui-dropzone';
import QuestionJsonApiHook from '../../../hooks/QuestionJsonApiHook';
import useThemeHook from '../../../hooks/ThemeHook';
import { useLocation } from 'react-router-dom';

const Question = () => {
  const location = useLocation();
  const { state } = location;
  const { userIdFromParent = '' } = state || {};

  const { xs_layout } = useThemeHook();
  const previewChip = {
    minWidth: 160,
    maxWidth: 210,
  };
  const {
    getAllQuestion,
    deleteQuestionById,
    updateQuestionById,
    createQuestion,
  } = QuestionApiHook();

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [submitFlag, setsubmitFlag] = useState(false);
  const [action, setAction] = useState('');
  const [deleteConfirmFlag, setDeleteConfirmFlag] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [showCrousel, setShowCrousel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const [drawerData, setDrawerData] = useState({});
  const [quesSec, setQuesSec] = useState(0);
  const [showOption, setShowOption] = useState(true);

  const dropdownArr = [
    { label: 'Admin', value: 'admin' },
    { label: 'Guest', value: 'guest' },
  ];

  const {
    data: userArr,
    isLoading: userDataLoader,
    refetch: questionListRefech,
  } = useQuery(['getAllQuestion'], () => getAllQuestion(), {
    enabled: true,
  });

  const { mutate: createQuestionMutate, isLoading: createQuestionLoader } =
    useMutation(createQuestion, {
      onSuccess: (data) => onSuccessRegister(data),
      onError: (data) => onErrorResgister(data),
    });
  const { mutate: updateQuestionByIdMutate, isLoading: updateUserLoader } =
    useMutation(updateQuestionById, {
      onSuccess: (data) => onSuccessRegister(data),
      onError: (data) => onErrorResgister(data),
    });
  const { mutate: deleteQuestionMutate, isLoading: deleteUserLoader } =
    useMutation(deleteQuestionById, {
      onSuccess: (data) => onSuccessRegister(data),
      onError: (data) => onErrorResgister(data),
    });

  const { readFileFromJSON } = QuestionJsonApiHook({ questionListRefech });

  const onSuccessRegister = (data) => {
    if (data.data.status) {
      setQuesSec(0);
      setShowCrousel(false);
      // setShowOption(false);
      setToggleDrawer(false);
      setCurrentId(null);
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'success',
      });
      questionListRefech();
    } else {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'error',
      });
    }
  };

  const onErrorResgister = (data) => {
    console.log('data', data);
    // setToggleDrawer(false);
  };

  const submitHandler = () => {
    setsubmitFlag(true);
    afterValidate(afterValidateCallBack);
  };

  const afterValidateCallBack = (second) => {
    setsubmitFlag(false);
    action === 'add'
      ? createQuestionMutate(drawerData)
      : updateQuestionByIdMutate(drawerData);
  };

  const userData = userArr?.data?.data?.data ?? [];

  useEffect(() => {
    let subs = true;
    setTimeout(() => {
      if (subs && showCrousel) {
        setQuesSec(quesSec + 1);
      }
    }, 1000);
    return () => {
      subs = false;
    };
  }, [showCrousel, quesSec]);

  const handleCancel = () => {
    setDeleteConfirmFlag(false);
  };

  const handleOk = () => {
    setDeleteConfirmFlag(false);
    deleteQuestionMutate(currentId);
  };

  const onQuestonChange = () => {
    setQuesSec(0);
  };

  const updateDuration = () => {
    const currentRow = userData.filter((o) => o.questionId == currentId)[0];
    updateQuestionByIdMutate({
      questionId: currentRow.questionId,
      data: { ...currentRow, duration: quesSec },
    });
  };

  return (
    <AuthLayout>
      {createQuestionLoader ||
        updateUserLoader ||
        deleteUserLoader ||
        (userDataLoader && (
          <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        ))}
      <Box sx={{ width: xs_layout ? '100%' : '90%', margin: '20px auto' }}>
        {/* <Box>
          <Button onClick={() => setShowCrousel(true)}>Show Crousel</Button>
        </Box> */}
        <Box>
          <DropzoneArea
            dropzoneText="Drag Json file to add questions"
            previewText="Selected files"
            onChange={(newFileObjs) => {
              if (newFileObjs.length > 0) {
                readFileFromJSON(newFileObjs);
              }
            }}
          />
        </Box>
        <MaterialTable
          columns={[
            {
              title: 'Ques Id',
              field: 'questionId',
              cellStyle: { width: '4%' },
            },
            // { title: 'UId', field: 'userId', cellStyle: { width: '4%' } },

            // {
            //   title: 'Type',
            //   field: 'questionType',
            //   cellStyle: { width: '4%' },
            // },

            {
              title: 'Duration',
              field: 'duration',
              cellStyle: { width: '4%' },
            },
            {
              title: 'Level',
              field: 'level',
              cellStyle: { width: '4%' },
            },
            {
              title: 'Question',
              field: 'question',
              cellStyle: { width: '80%' },
              render: (rowData) => {
                return rowData.questionType == 'Text'
                  ? rowData.question
                  : rowData.questionLabel;
              },
            },
            {
              title: 'Courses',
              field: 'courses',
              cellStyle: { width: '4%' },
            },
            {
              title: 'Choice',
              field: 'questionChoiceType',
              cellStyle: { width: '4%' },
            },
          ]}
          data={userData}
          title="Questions"
          onRowClick={(event, rowData, togglePanel) => {
            setAction('edit');
            setDrawerData(rowData);
            setToggleDrawer(true);
          }}
          actions={[
            {
              icon: 'add',
              tooltip: 'Add User',
              isFreeAction: true,
              onClick: (event) => {
                setAction('add');
                setDrawerData({});
                setToggleDrawer(true);
              },
            },
            {
              icon: 'refresh',
              tooltip: 'Refresh',
              isFreeAction: true,
              onClick: (event) => {
                questionListRefech();
              },
            },
            (rowData) => ({
              icon: 'delete',
              tooltip: 'Delete User',
              onClick: (event, rowData) => {
                setDeleteConfirmFlag(true);
                setCurrentId(rowData.questionId);
              },
              disabled: rowData.birthYear < 2000,
            }),
            (rowData) => ({
              icon: 'edit',
              tooltip: 'Show crousel',
              onClick: (event, rowData) => {
                setQuesSec(0);
                setCurrentId(rowData.questionId);

                setSelectedItem(rowData.tableData.id);
                setShowCrousel(true);
              },
              disabled: rowData.birthYear < 2000,
            }),
          ]}
          options={{
            actionsColumnIndex: 0,
            grouping: true,
            pageSize: 10,
            search: true,
            searchText: '' + userIdFromParent,
          }}
        />
      </Box>
      {showCrousel && (
        <Dialog
          sx={{
            '& .MuiDialog-paper': { width: '100%' },
          }}
          open={true}
        >
          <DialogContent dividers>
            {/* <Carousel
              showIndicators={false}
              showArrows={true}
              onChange={onQuestonChange}
              selectedItem={selectedItem}
            > */}
            {Object.keys(userData).length > 0 &&
              userData.map((o) => {
                return (
                  currentId == o.questionId && (
                    <Grid container style={{}} key={o.questionId}>
                      <Grid item xs={6}>
                        <Box mb={2}>
                          <Typography variant="body">{o.questionId}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box mb={2}>
                          <Typography variant="body">{quesSec}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          style={{
                            minHeight: 200,
                            backgroundColor: '#fff',
                            padding: 10,
                            border: '1px solid',
                            borderRadius: 10,
                          }}
                        >
                          <Typography variant="body" style={{ fontSize: 18 }}>
                            {o.question}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        {showOption ? (
                          o.questionOptions.map((p, pi) => {
                            return (
                              <Box
                                style={{
                                  padding: 16,
                                  border: '1px solid',
                                  marginTop: 10,
                                }}
                                key={p.option + pi}
                                onClick={updateDuration}
                              >
                                <Typography variant="body">
                                  {p.option}
                                </Typography>
                              </Box>
                            );
                          })
                        ) : (
                          <Button
                            autoFocus
                            onClick={() => {
                              setShowOption(true);
                            }}
                          >
                            ShowOption
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )
                );
              })}
            {/* </Carousel> */}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                // setShowOption(false);
                setShowCrousel(false);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {toggleDrawer && (
        <Drawer
          open={true}
          anchor="right"
          onClose={() => setToggleDrawer(false)}
        >
          <Box sx={[xs_layout ? { p: 1 } : { p: 2, width: '400px' }]}>
            <CreateQuestion
              preview={false}
              setToggleDrawer={setToggleDrawer}
              questionListRefech={questionListRefech}
              questionId={currentId}
              currentQuestionData={drawerData}
            />
          </Box>
        </Drawer>
      )}
      {deleteConfirmFlag && (
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth="xs"
          open={true}
        >
          <DialogTitle>Do you want to delete this Question?</DialogTitle>
          <DialogContent dividers>
            <Typography fontWeight="700">QuestionId {currentId}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Ok</Button>
          </DialogActions>
        </Dialog>
      )}
      {Object.keys(snakeBarProps).length > 0 && (
        <CustomSnackbar
          {...snakeBarProps}
          setSnakeBarProps={setSnakeBarProps}
        />
      )}
    </AuthLayout>
  );
};

export default Question;
