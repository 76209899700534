import { Box, Grid } from '@mui/material';
import React from 'react';
import { responsiveStype } from '../../theme/responsive';
import useThemeHook from '../../hooks/ThemeHook';

export const UnAuthLayout = (props) => {
  const { xs_layout } = useThemeHook();

  return (
    <Box sx={responsiveStype.UnAuthLayout.MaincWarpper}>
      <Grid container className="height_100">
        <Grid item xs={12}>
          <Grid
            container
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="height_100"
          >
            <Grid item xs={xs_layout ? 11 : 4}>
              {props.children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
