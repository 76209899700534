import { apiConstant } from '../services/apiConstants';
import useRequestHook from '../services/request';

const UserApiHook = () => {
  const { Request } = useRequestHook();

  const getUser = (data) => {
    return Request.get(apiConstant.allUser, data);
  };
  const updateUserById = (data) => {
    // console.log('data', data);
    return Request.put(apiConstant.user + '/' + data.userId, data);
  };
  const getUserById = (userId) => {
    console.log('userId', userId);
    return Request.get(apiConstant.user + '/' + userId);
  };
  const deleteUserById = (userId) => {
    // console.log('data', data);
    return Request.delete(apiConstant.user + '/' + userId);
  };
  const verifyUserEmail = (data) => {
    // console.log('data', data);
    return Request.post(apiConstant.emailVerify, data);
  };
  return {
    getUser,
    updateUserById,
    getUserById,
    deleteUserById,
    verifyUserEmail,
  };
};

export default UserApiHook;
