import { Box, Grid, Link } from '@mui/material';
import React from 'react';
import { footerArr } from '../models/SideMenu';
import { Typography } from '@material-ui/core';

function HomeLayout(props) {
  const { children } = props;
  return (
    <Box>
      {children}
      <Box
        style={{
          backgroundColor: '#3C3C3C',
          padding: 10,
          position: 'fixed',
          bottom: 0,
          width: '100%',
        }}
      >
        <Grid item xs={12}>
          <Grid container item xs={12} justifyContent="center">
            <Box>
              {footerArr.map((o) => {
                return (
                  <Link
                    href={o.route}
                    style={{ marginRight: 20, color: '#fff' }}
                  >
                    <Typography variant="span" color="#fff" style={{}}>
                      {o.label}
                    </Typography>
                  </Link>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default HomeLayout;
