import React from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import { Typography } from '@mui/material';
import { chipMappedNameArr } from '../../../models/SideMenu';

function ChipBoxInput(props) {
  const { dataArr, chipType, setConfigData } = props;

  const getChipLabel = (label) => {
    return chipMappedNameArr.filter((o) => o.mappedKey == label)[0].label;
  };

  const updateInputValue = (value) => {
    setConfigData({ ...dataArr, [chipType]: value });
  };

  return (
    <Grid container item xs={12} style={{ marginBottom: 20 }}>
      <Grid item xs={12} md={2}>
        <Box>
          <Typography variant="body">{getChipLabel(chipType)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={10}>
        <TextField
          id="outlined-required"
          // label={getChipLabel(chipType)}
          style={{ width: '100%' }}
          value={dataArr[chipType]}
          // onChange={(e) => console.log('e', e)}
          onChange={(e) => updateInputValue(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}

export default ChipBoxInput;
